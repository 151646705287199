body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@media only screen and (max-width: 769px) {
  .tablelead .table-inside:nth-child(4) img {
    display: none;
  }
}

@media only screen and (max-width: 600px) {

  .kutia.login,
  .kutia.register {
    width: 85% !important;
  }

  .tablelead .table-inside:nth-child(3) {
    padding-top: 25px !important;
  }
}

/* SCROLL BAR */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: -webkit-linear-gradient(top, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
  background: linear-gradient(to bottom, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: -webkit-linear-gradient(top, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
  background: linear-gradient(to bottom, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
}

.content .middle-content.entry {
  display: flex;
  justify-content: center;
  flex: 1;
}

.content .middle-content .entry-left img {
  position: relative;
  float: left;
  width: 500px;
  object-fit: contain;
  margin-top: 2%;
  margin-bottom: 2rem;
  align-self: center;
}

.content .middle-content .entry-left .auth-buttons-container {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: relative; */
  /* float: left; */
  width: 100%;
  margin-top: 2%;
  margin-bottom: 0;
}

.content .middle-content .entry-left {
  flex: 1;
  position: relative;
  float: left;
  width: 40%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-right: 3rem;
}

.content .middle-content .entry-left a.button {
  align-self: center;
  color: #fff;
  transition: .1s linear all;
}

.content .middle-content .entry-left a:hover {
  transform: scale(1.025);
  text-decoration: none;
}

.content .middle-content .entry-left a.secondary {
  color: black;
  background-color: white;
  border: 1px solid black;
  text-decoration: none;
}

.content .middle-content .entry-right {
  flex: 1;
  margin-top: 2%;
  position: relative;
  float: left;
  width: 50%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content .middle-content .entry-right .nav-logo {
  width: 25%;
}

.content .middle-content .entry-right span.title {
  background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 50px;
  font-weight: bold;
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  z-index: 1;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 1.2;
  font-size: 3.5em;
}

a.button,
.content .middle-content .entry-right a.button {
  width: 25rem;
  align-self: flex-start;
  text-align: center;
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  background-color: #0083d3;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  margin: 1rem 0.5rem 0 0rem;
  text-decoration: none;
  transition: .1s linear all;
}

.content .middle-content .entry-right .logos-wrapper {
  align-self: center;
  margin-top: 1rem;
  width: 100%;
  margin-left: 3rem;
}

.content .middle-content .entry-right .logos-wrapper a {
  height: 2rem;
}

.content .middle-content .entry-right .logos-wrapper .social-logo {
  height: 2rem;
  width: auto;
  margin-right: 1rem;
  align-self: flex-start;
}

.content .middle-content .entry-right a.primary {
  margin-top: 3rem
}

.content .middle-content .entry-right a.secondary {
  color: black;
  background-color: white;
  border: 1px solid black;
  margin-bottom: 1rem;
}

.content .middle-content .entry-right a:hover {
  transform: scale(1.025);
}

.content .middle-content .entry-right .entry-text {
  font-size: 1.25rem;
  max-width: 80%;
  align-self: flex-start;
}

.content .middle-content .entry-right .info-links-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-left: 1rem;
}

.content .middle-content .entry-right .info-link {
  display: flex;
  font-size: 1.25rem;
  max-width: 100%;
  align-self: flex-start;
}

.content .middle-content .entry-right .swiss-ad img {
  width: 400px;
  max-width: 70%;
  margin-right: 1rem;
  align-self: flex-end;
  float: right;
}

@media only screen and (max-width: 1500px) {
  .content .middle-content .entry-right .entry-text {

    max-width: 90%;
  }
}

@media only screen and (max-width: 850px) {
  .content .middle-content .entry-right span.title {

    font-size: 2.5em;
  }

  .content .middle-content .entry-right a {
    width: 21rem;
  }
}

@media only screen and (max-width: 1000px) {

  .content .middle-content.entry {
    margin-top: 5%;
    display: block;
    justify-content: center;
  }

  .content .middle-content .entry-left img {
    position: relative;
    width: 400px;
    object-fit: contain;
  }

  .content .middle-content .entry-left {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .content .middle-content .entry-right {
    position: relative;
    /* float: left; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content .middle-content .entry-right span.title {
    font-size: 2.5rem;
    text-align: center;
  }

  .content .middle-content .entry-right .entry-text {
    font-size: 1.25rem;
    width: 75%;
    text-align: center;
    margin: 1rem auto;
  }

  .content .middle-content .entry-right .info-links-container {
    font-size: 1.25rem;
    width: 75%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
  }

  .content .middle-content .entry-right .logos-wrapper {
    /* font-size: 1.25rem;
    width: 75%;
    text-align: center; */
    align-self: center;
    display: flex;
    width: fit-content;
    /* flex-direction: column; */
    /* margin: 1rem auto; */
  }

  .content .middle-content .entry-left .auth-buttons-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: relative; */
    /* float: left; */
    width: 100%;
    margin-top: 2%;
    margin-bottom: 0;
  }

  .content .middle-content .entry-left a.button {
    align-self: center;
    color: #fff;
    transition: .1s linear all;
    text-decoration: none;
    border: none;
  }

  .content .middle-content .entry-left a.secondary {
    color: black;
    background-color: white;
    border: 2px solid black;
    text-decoration: none;
  }

}


.entry-page {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 500px;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
}

.entry-page .entry-left-part {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

.entry-page .entry-right-part {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.entry-page .entry-right-part p {
  width: 50%;
  color: #fff;
  font-size: 18px;
}

.edit-profile {
  width: 100%;
  height: auto;
}

.edit-profile .grid-box {
  margin-bottom: 0px;
}

.user-profile span.title {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  color: transparent;
  background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

.input-div {
  position: relative;
  float: left;
  height: auto;
  background-color: #fff;
  margin-bottom: 15px;
  transition: .3s linear all;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.input-div input {
  position: relative;
  float: left;
  height: 40px;
  border: none;
  background-color: #028ccf;
  color: #fff;
  padding-left: 5px;
  margin-bottom: 10px;
  width: 200px;
  border-radius: 0px;
}

.address-div {
  margin-top: 30px;
}

.input-div input::placeholder {
  color: #fff;
}

.edit-profile span.placeholder {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 27px;
}

.team {
  position: relative;
  float: left;
  width: 100%;
}

.team.show {

  margin: 0.5em 0.2em;
  display: flex;
  flex-direction: row;
}

.team.show {
  background: #f5f5f5;
}

.team.show .input-div .team-buttons-div {
  position: relative;
  float: left;
  height: auto;
  transition: .3s linear all;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.team.show .input-div.col-left {
  width: 40%;
  flex-direction: column;
  padding-top: 10px;
}

.team.show .input-div.col-right {
  width: 60%;
  flex-direction: column;
  padding-top: 10px;
}

.team.show .input-div,
.einladung .input-div {
  background: #fff0;
}

.team.einladung .input-div {
  flex-direction: column;
  width: 100%;
}


.team .input-div .team-buttons-div {
  justify-content: center;
  align-items: center;

}

.team .input-div {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.team .input-div input {
  width: 300px;
  margin-bottom: 0px;
}

.team .input-div span {
  font-size: 1.25em;
  width: 250px;
  text-align: center;
  justify-content: center;
}

.team .input-div .team-header {
  font-size: 1.75em;
  width: 12em;
  font-weight: 600;
}


.team .input-div .team-box-names {
  width: 80%;
  margin: 0.75em 2em;
  max-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.team .input-div .team-box-names span {
  position: relative;
  float: left;
  width: 100%;
  font-size: 18px;
  text-align: left;
}

.user-profile span.title.level2 {
  margin-bottom: 5px;
}


.teamMemberItem {
  background: #e4edf2;
  border-bottom: 0.1em solid #bddbec;
  margin: 0.1em;
  padding: 0.5em;
}

.status-div {
  width: auto;
  float: right;
  border: 1px solid #208ccf;
  color: #028ccf;
  border-radius: 5px;
  padding: 0px 5px;
}

.button-div {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.button-div button,
.team .input-div button {
  width: 250px;
  height: 36px;
  background-color: #028ccf;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 2em;
}

.user-profile .gender {
  display: inherit;
  justify-content: space-around;
  width: 100%;
}

.user-profile .gender div {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.user-profile .team.team-grunden .gender {
  justify-content: center;
}

.user-profile .team.team-grunden .gender div {
  margin: 0px 20px;
}

.user-profile .gender div input {
  width: 15px;
  height: 15px;
}

.user-profile .gender div label {
  margin-bottom: 0px;
  margin-left: 5px;
}

.user-profile .team.team-grunden span {
  width: 100%;
  text-align: center;
  position: relative;
  float: left;
  font-size: 18px;
}

.user-profile .team.team-grunden span.title {
  font-size: 35px;
}

.user-popup {
  position: fixed;
  width: 0;
  height: 0;
  z-index: 9999;
  overflow: hidden;
}

.user-popup.active {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5)
}

.user-popup.active .popup-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 400px;
  height: auto;
  padding: 30px;
}

.user-popup.active .popup-box span.text {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.user-popup.active .popup-box .popup-buttons {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.user-popup.active .popup-box .popup-buttons span {
  position: relative;
  float: left;
  width: 100px;
  height: 40px;
  border: 0;
  background-color: #0083d3;
  border-radius: 2em;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.gender input {
  display: none;
}

input:disabled {
  cursor: no-drop;
}

.radioButton {
  width: 20px;
  height: 20px;
  border: 1px solid #0083d3
}

.radioButton.active {
  padding: 3px;
}

.radioButton.active .circle {
  height: 100%;
  width: 100%;
  background-color: #0083d3;
  border-radius: 50%;
  margin: 0 !important;
}

.radioButton.active .circle-light {
  height: 100%;
  width: 100%;
  background-color: #9edaff;
  border-radius: 50%;
  margin: 0 !important;
}

.submission .submit-button,
.question-wrapper .submit-button {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px
}

.submission .submit-button button,
.question-wrapper .submit-button button {
  position: relative;
  width: 200px;
  height: 30px;
  color: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
  background: -webkit-linear-gradient(top, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  border-radius: 2em;
  border: none;
  line-height: 10px;
  cursor: pointer;
  outline: none;
}




.chatmenu {
  /* width: 50%; */
  position: relative;
  width: 300px !important;
  /* height: 30px; */
  color: #fff;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%) !important;
  border-radius: 2em;
  border: none;
  line-height: 10px;
  cursor: pointer;
  margin-left: 33%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600 !important;
}

a,
span,
h1,
p {
  font-weight: 600;
}

.grayout {
  opacity: 0.6;
  /* Real browsers */
  filter: alpha(opacity=60);
  /* MSIE */
}

.CodeMirror {
  height: 100% !important;
}
