.main-wrapper {

  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-self: center;
  width: 100%;
  margin-top: 3rem;
  min-height: 60vh;
}

.main-wrapper .message-prompt {
  width: 60%;
  max-width: 1200px;
  font-size: 1.25rem;
  /* font-weight: bold; */
  text-align: justify;
  margin: 1rem auto;
}

.main-wrapper .main-header {
  font-size: 2rem;
  font-weight: bold;
  margin: 0 auto;
}

.main-wrapper .ethics-sub-header {
  width: 80%;
  max-width: 900px;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: left;
  display: flex;
  align-self: center;
}

.definition-wrapper-temp {
  display: flex;
  justify-content: center;
}

.definition-item-temp {
  display: flex;
  height: 2rem;
  width: 2rem;
  background: rgba(0, 0, 0, 0.075);
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0.25rem 1rem;
  cursor: pointer;
}


.path-options {
  display: flex;
  flex-wrap: wrap;
  justify-self: center;
  justify-content: center;
  gap: 5%;
}

.path-option {
  /* background: rgba(0, 0, 0, 0.1); */
  margin: 1rem 2rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: black;
  cursor: pointer;
  width: 20rem;
}

.path-option:hover {
  transform: scale(1.2);
}

.path-option .path-option-title {
  font-size: 1.5rem;
}

.ethics-content-wrapper {
  display: grid;
  grid-template-rows: max-content;
  height: 100%;
  width: 100%;
  min-height: 60vh;
}

.panel-list {
  display: flex;
  justify-content: center;
}

ul.panel-list li {
  margin: 1rem;
  display: grid;
}

.panel-item-header,
ul.panel-list li img.panel-item-header {
  justify-self: center;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}

.panel-item-img,
ul.panel-list li img.panel-item-img {
  height: 10rem;
  justify-self: center;
  margin: 1rem;
}

ul.panel-list li .panel-item-check {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  justify-self: center;
}


/*scenarios */

.scenario-wrapper {
  display: grid;
  width: 80%;
  max-width: 75rem;
  justify-self: center;
}

.scenario-intro-wrapper {
  display: grid;
  position: relative;
  width: 100%;
  padding: 1rem;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
}

.scenario-intro-header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row: 1;
  grid-column: 1/span 2;
  margin: 0 1rem 2rem 1rem;
}

.scenario-intro-header .header-img {
  width: 15rem;
  display: flex;
  align-self: center;
}

.scenario-intro-header .header-title {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1rem 1rem 1rem 2rem;
}

.header-title h2 {
  font-weight: bold !important;
}

.header-title div {
  font-weight: 600;
  font-size: 1.25rem;
  text-align: left;
}

.header-title.sub-header-center div {
  text-align: center;
}


.scenario-intro-wrapper .scenario-info {
  display: flex;
  flex-direction: column;
  grid-row: 3;
  grid-column: 1;
  align-self: center;
}

.scenario-intro-wrapper .audio-wrapper {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.25rem 0;
  border-radius: 0.5rem;
  width: auto;
}

.scenario-intro-wrapper .audio-wrapper:hover {
  background: rgba(0, 0, 0, 0.025);
}

.scenario-intro-wrapper .audio-wrapper img {
  width: 2rem;
  border-radius: 1rem;
  padding: 1px;
  margin: 0 0.5rem;
}

.scenario-intro-wrapper .scenario-header {
  text-align: left;
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0 1rem;
}

.scenario-intro-wrapper .scenario-desc {
  padding: 1rem;
  font-size: 1.2rem;
}

.scenario-intro-wrapper .scenario-img {
  grid-row: 3;
  grid-column: 2;
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  padding: 1rem 0;
}

.scenario-intro-wrapper .scenario-img img {
  width: 80%;

}

.scenario-intro-wrapper button {
  grid-column: 1/ span 2;
  width: 10rem;
  justify-self: center;
  margin: 1rem;
}

.button-wrapper.scenarios-selection-actions,
.button-wrapper.scenarios-end-actions {
  display: flex;
  justify-content: space-around;
}

.button-wrapper.scenarios-selection-actions button {
  width: 20rem;
}

.button-wrapper.scenarios-end-actions button {
  width: 17.5rem;
}

.conversation-wrapper {
  display: grid;
  margin: 1rem;
}

.conversation-wrapper .conversation-message {
  background: rgba(0, 0, 0, 0.075);
  width: 80%;
  justify-self: center;
  padding: 2rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.conversation-panel {
  display: flex;
  justify-self: center;
  justify-content: space-between;
}

.pros-cons-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
}

.summary-list-header {
  margin: 0 1rem;
  font-size: 1.5rem;
  color: #AAA;
  font-weight: bold;

}

.summary-item {
  display: grid;
  /* flex-direction: column; */
  justify-self: center;
  margin: 1rem;
  font-size: 1rem;
}

.summary-desc {
  background: rgba(0, 0, 0, 0.075);
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  font-weight: 600;
}

.summary-info {
  display: flex;
  max-width: max-content;
  justify-self: right;
  font-size: 1.2rem;
}

.actions {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;

}

.actions button {
  width: 10rem;
  margin: 0 1rem;
  padding: 0.5rem 1rem;
}

/* -------- */

.overlay-panel .section:first-child {
  padding-top: 0;
  font-size: 20px;
  text-align: justify;
}

.intro.ethics {
  margin: 2rem 0;
}

.intro.ethics p {
  text-align: justify;
}

.intro.ethics img {
  height: 20rem !important;
  display: flex;
  justify-self: center;
  margin: 0 auto !important;
}

.intro.intro.ethics img.spread-vertical {
  height: auto !important;
}

.intro.ethics img.interactive-svg-main {
  width: 100% !important;
  height: auto !important;
  margin: 0 auto !important;
}

.intro.ethics.columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.intro.ethics.columns-2 img {
  grid-row: 1/span 10;
  grid-column: 2;
  height: auto !important;
  width: 90% !important;
}

.checklist-img {
  height: auto;
  width: 20rem;
  margin: auto;
  display: block;
  cursor: pointer !important;
}


/* --- */

.info-panel .svgfuture-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info-panel .svgfuture-wrapper .audio-wrapper {
  position: absolute;
  bottom: 0rem;
  right: -7.5rem;
  text-align: center;
  width: 7.5rem;
}

.audio-iframe-wrapper,
.info-panel .svgfuture-wrapper .audio-iframe-wrapper {
  display: flex;
  flex-direction: column;
}

.overlay-panel.include-audio .audio-iframe-wrapper {
  margin: 0 2rem;
}

.audio-iframe-wrapper .audio-title,
.info-panel .svgfuture-wrapper .audio-iframe-wrapper .audio-title {
  font-size: 1.25rem;
  margin: 1rem 1rem 0 1rem;
  font-weight: bold;
}

.info-panel .svgfuture-wrapper .audio-wrapper.bt-5 {
  margin-bottom: 5rem;
}

.info-panel .svgfuture-wrapper .audio-wrapper img {
  width: 7.5rem;
  height: auto;
  cursor: pointer;
}

.audio-iframe,
.info-panel .svgfuture-wrapper .audio-iframe {
  height: 5rem;
}

.overlay-panel .audio-wrapper {
  position: relative;
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  top: -1.25rem;
  cursor: pointer;
}

.overlay-panel .audio-wrapper img {
  width: 2rem;
  border-radius: 50%;
  padding: 1px;
  margin: 0rem 1rem 0 0.5rem;
}

/* .info-panel .members-wrapper {} */

.info-panel .members-wrapper .member-speech-list {
  margin: 0 0 0rem 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.info-panel .members-wrapper .member-speech-list .speech-item {
  margin: 1rem 0.25rem;
  display: grid;
  width: 10rem;
  align-items: flex-end;
  grid-template-rows: 1fr auto;
}

.info-panel .members-wrapper .member-speech-list .speech-item .speech-header {
  font-size: 1rem;
  text-align: center;
  align-self: flex-start;
}

.info-panel .members-wrapper .member-speech-list .speech-item .speech-header span {
  font-weight: bold;
  display: block;
}

.info-panel .members-wrapper .member-speech-list .speech-item .img {
  height: 4rem;
  width: 4rem;
  justify-self: center;
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}

.info-panel .members-wrapper .panel-info {
  font-size: 1.25rem;
}

.info-panel .members-wrapper .panel-info:first {
  margin-top: 2rem;
}

.intro-section .intro-wrapper .intro.ethics img.link-icon {
  height: 1em !important;
  display: inline-flex;
  padding: 0 0.5rem;
  cursor: pointer;
  margin: 0 !important;
  pointer-events: none;
}

.intro-section .intro-wrapper .intro.ethics img.link-icon:hover {
  background: rgba(0, 0, 0, 0.075)
}

.intro-section .intro-wrapper .intro.ethics .shortcut-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-content: center;
  max-width: 900px;
  margin: 2rem 0 1rem 0;
}

.intro-section .intro-wrapper .intro.ethics .shortcut-wrapper button {
  display: flex;
  /* min-width: 17.5rem; */
  height: 2.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #EEE;
  color: #999;
  font-size: 1.2rem;
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.intro-section .intro-wrapper .intro.ethics .shortcut-wrapper button:hover {
  text-decoration: none;
  color: #333 !important;
  transform: scale(1.025);
}

.intro-section .intro-wrapper .intro.ethics .shortcut-wrapper a:hover {
  text-decoration: none;
}

/* .intro-section .intro-wrapper .intro.ethics .shortcut-wrapper button img.link-icon {
  pointer-events: none;
} */

/* *************************** */
/*    CONCLUSION: ETHIKRAT     */
/* *************************** */

.info-panel .members-wrapper .fields-container {
  margin: 0 0 0rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info-panel .members-wrapper .fields-container .field-item {
  margin: 1rem 0.25rem;
  width: 12rem;
  align-items: flex-end;
  grid-template-rows: 1fr auto;
  cursor: pointer;
}

.info-panel .members-wrapper .fields-container .field-item .field-header {
  font-size: 1.25rem;
  text-align: center;
  align-self: flex-start;
}

.info-panel .members-wrapper .fields-container .field-item .field-header span {
  font-weight: bold;
  display: block;
}

.info-panel .members-wrapper .fields-container .field-item .img-wrapper {
  height: 10rem;
  width: 10rem;
  justify-self: center;
  margin: auto;
  margin-bottom: 0.75rem;
  padding: 0.3rem;
  border-radius: 50%;
}

.info-panel .members-wrapper .fields-container .field-item img.img {
  justify-self: center;
  border-radius: 50%;
}


audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #a0276e;
  color: #a0276e;
}

audio::-webkit-media-controls-panel {
  background-color: blue !important;
}

.react-audio-player {
  width: '100%';
  height: '4rem';
  -webkit-tap-highlight-color: red
}


.video-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 500px;
  margin: 1.5rem 0;
}

.video-wrapper iframe {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 3rem;
}

.options-wrapper .options {
  display: flex;
  justify-content: center;
}

.options-wrapper .options-header {
  font-size: 1.25rem;
  display: flex;
  justify-content: center;

}

.options-wrapper .options.button-wrapper Button {
  width: 10rem;
  margin: 0 1rem
}



/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1.5rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #eee;
  border: 2px solid #777;
  border-radius: 0.25rem;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: #00E3A7;
  border: 2px solid #00E3A7;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 0.75rem;
  top: 0.2rem;
  width: 0.8rem;
  height: 1.5rem;
  border: solid white;
  border-width: 0 5px 5px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.interactive-path {
  fill: transparent;
  cursor: pointer;
  pointer-events: all;
}

.interactive-path:hover {
  fill: #00E3A755;
  stroke: #00E3A7;
  stroke-width: 3px;
}

.background-image-style {
  display: flex;
  height: 4rem;
  grid-row: 1;
}

.overlay-panel-container .overlay-panel.with-img {
  grid-template-rows: 6rem 1fr auto;
}

.ethics-overlay-header {
  grid-row: 1;
  display: flex;
  margin: 0 3rem;
  height: fit-content;
  align-self: center;
}

.ethics-overlay-header img {
  margin-right: 1rem;
}