body {
  margin: 0;
  padding: 0;
  /* font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

/* code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
} */

@media only screen and (max-width: 769px) {
  .tablelead .table-inside:nth-child(4) img {
    display: none;
  }
}

@media only screen and (max-width: 600px) {

  .kutia.login,
  .kutia.register {
    width: 85% !important;
  }

  .tablelead .table-inside:nth-child(3) {
    padding-top: 25px !important;
  }
}

/* SCROLL BAR */
/* width */
::-webkit-scrollbar {
  width: 7px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey;
  border-radius: 10px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: linear-gradient(to bottom, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: linear-gradient(to bottom, #15e4ae 0, #0cbabe 51%, #0083d3 100%);
}

.content .middle-content.entry {
  display: flex;
  justify-content: center;
  flex: 1 1;
}

.content .middle-content .entry-left img {
  position: relative;
  float: left;
  width: 500px;
  object-fit: contain;
  margin-top: 2%;
  margin-bottom: 2rem;
  align-self: center;
}

.content .middle-content .entry-left .auth-buttons-container {
  align-self: flex-start;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* position: relative; */
  /* float: left; */
  width: 100%;
  margin-top: 2%;
  margin-bottom: 0;
}

.content .middle-content .entry-left {
  flex: 1 1;
  position: relative;
  float: left;
  width: 40%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 1.5rem;
  margin-right: 3rem;
}

.content .middle-content .entry-left a.button {
  align-self: center;
  color: #fff;
  transition: .1s linear all;
}

.content .middle-content .entry-left a:hover {
  transform: scale(1.025);
  text-decoration: none;
}

.content .middle-content .entry-left a.secondary {
  color: black;
  background-color: white;
  border: 1px solid black;
  text-decoration: none;
}

.content .middle-content .entry-right {
  flex: 1 1;
  margin-top: 2%;
  position: relative;
  float: left;
  width: 50%;
  /* height: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}

.content .middle-content .entry-right .nav-logo {
  width: 25%;
}

.content .middle-content .entry-right span.title {
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 50px;
  font-weight: bold;
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  z-index: 1;
  text-transform: uppercase;
  font-family: "Open Sans";
  font-weight: 700;
  line-height: 1.2;
  font-size: 3.5em;
}

a.button,
.content .middle-content .entry-right a.button {
  width: 25rem;
  align-self: flex-start;
  text-align: center;
  padding: 10px 15px;
  border-radius: 10px;
  border: none;
  background-color: #0083d3;
  color: #fff;
  font-size: 1.25rem;
  font-weight: 600;
  cursor: pointer;
  outline: none;
  margin: 1rem 0.5rem 0 0rem;
  text-decoration: none;
  transition: .1s linear all;
}

.content .middle-content .entry-right .logos-wrapper {
  align-self: center;
  margin-top: 1rem;
  width: 100%;
  margin-left: 3rem;
}

.content .middle-content .entry-right .logos-wrapper a {
  height: 2rem;
}

.content .middle-content .entry-right .logos-wrapper .social-logo {
  height: 2rem;
  width: auto;
  margin-right: 1rem;
  align-self: flex-start;
}

.content .middle-content .entry-right a.primary {
  margin-top: 3rem
}

.content .middle-content .entry-right a.secondary {
  color: black;
  background-color: white;
  border: 1px solid black;
  margin-bottom: 1rem;
}

.content .middle-content .entry-right a:hover {
  transform: scale(1.025);
}

.content .middle-content .entry-right .entry-text {
  font-size: 1.25rem;
  max-width: 80%;
  align-self: flex-start;
}

.content .middle-content .entry-right .info-links-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-self: center;
  padding-left: 1rem;
}

.content .middle-content .entry-right .info-link {
  display: flex;
  font-size: 1.25rem;
  max-width: 100%;
  align-self: flex-start;
}

.content .middle-content .entry-right .swiss-ad img {
  width: 400px;
  max-width: 70%;
  margin-right: 1rem;
  align-self: flex-end;
  float: right;
}

@media only screen and (max-width: 1500px) {
  .content .middle-content .entry-right .entry-text {

    max-width: 90%;
  }
}

@media only screen and (max-width: 850px) {
  .content .middle-content .entry-right span.title {

    font-size: 2.5em;
  }

  .content .middle-content .entry-right a {
    width: 21rem;
  }
}

@media only screen and (max-width: 1000px) {

  .content .middle-content.entry {
    margin-top: 5%;
    display: block;
    justify-content: center;
  }

  .content .middle-content .entry-left img {
    position: relative;
    width: 400px;
    object-fit: contain;
  }

  .content .middle-content .entry-left {
    position: relative;
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .content .middle-content .entry-right {
    position: relative;
    /* float: left; */
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .content .middle-content .entry-right span.title {
    font-size: 2.5rem;
    text-align: center;
  }

  .content .middle-content .entry-right .entry-text {
    font-size: 1.25rem;
    width: 75%;
    text-align: center;
    margin: 1rem auto;
  }

  .content .middle-content .entry-right .info-links-container {
    font-size: 1.25rem;
    width: 75%;
    text-align: center;
    display: flex;
    flex-direction: column;
    margin: 1rem auto;
  }

  .content .middle-content .entry-right .logos-wrapper {
    /* font-size: 1.25rem;
    width: 75%;
    text-align: center; */
    align-self: center;
    display: flex;
    width: -moz-fit-content;
    width: fit-content;
    /* flex-direction: column; */
    /* margin: 1rem auto; */
  }

  .content .middle-content .entry-left .auth-buttons-container {
    align-self: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    /* position: relative; */
    /* float: left; */
    width: 100%;
    margin-top: 2%;
    margin-bottom: 0;
  }

  .content .middle-content .entry-left a.button {
    align-self: center;
    color: #fff;
    transition: .1s linear all;
    text-decoration: none;
    border: none;
  }

  .content .middle-content .entry-left a.secondary {
    color: black;
    background-color: white;
    border: 2px solid black;
    text-decoration: none;
  }

}


.entry-page {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 500px;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
}

.entry-page .entry-left-part {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 10px;
}

.entry-page .entry-right-part {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.entry-page .entry-right-part p {
  width: 50%;
  color: #fff;
  font-size: 18px;
}

.edit-profile {
  width: 100%;
  height: auto;
}

.edit-profile .grid-box {
  margin-bottom: 0px;
}

.user-profile span.title {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  color: transparent;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  font-size: 35px;
  text-align: center;
  font-weight: bold;
  margin-bottom: 30px;
}

.input-div {
  position: relative;
  float: left;
  height: auto;
  background-color: #fff;
  margin-bottom: 15px;
  transition: .3s linear all;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.input-div input {
  position: relative;
  float: left;
  height: 40px;
  border: none;
  background-color: #028ccf;
  color: #fff;
  padding-left: 5px;
  margin-bottom: 10px;
  width: 200px;
  border-radius: 0px;
}

.address-div {
  margin-top: 30px;
}

.input-div input::placeholder {
  color: #fff;
}

.edit-profile span.placeholder {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 27px;
}

.team {
  position: relative;
  float: left;
  width: 100%;
}

.team.show {

  margin: 0.5em 0.2em;
  display: flex;
  flex-direction: row;
}

.team.show {
  background: #f5f5f5;
}

.team.show .input-div .team-buttons-div {
  position: relative;
  float: left;
  height: auto;
  transition: .3s linear all;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}


.team.show .input-div.col-left {
  width: 40%;
  flex-direction: column;
  padding-top: 10px;
}

.team.show .input-div.col-right {
  width: 60%;
  flex-direction: column;
  padding-top: 10px;
}

.team.show .input-div,
.einladung .input-div {
  background: #fff0;
}

.team.einladung .input-div {
  flex-direction: column;
  width: 100%;
}


.team .input-div .team-buttons-div {
  justify-content: center;
  align-items: center;

}

.team .input-div {
  width: 100%;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px;
}

.team .input-div input {
  width: 300px;
  margin-bottom: 0px;
}

.team .input-div span {
  font-size: 1.25em;
  width: 250px;
  text-align: center;
  justify-content: center;
}

.team .input-div .team-header {
  font-size: 1.75em;
  width: 12em;
  font-weight: 600;
}


.team .input-div .team-box-names {
  width: 80%;
  margin: 0.75em 2em;
  max-height: 220px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  overflow-y: auto;
  overflow-x: hidden;
}

.team .input-div .team-box-names span {
  position: relative;
  float: left;
  width: 100%;
  font-size: 18px;
  text-align: left;
}

.user-profile span.title.level2 {
  margin-bottom: 5px;
}


.teamMemberItem {
  background: #e4edf2;
  border-bottom: 0.1em solid #bddbec;
  margin: 0.1em;
  padding: 0.5em;
}

.status-div {
  width: auto;
  float: right;
  border: 1px solid #208ccf;
  color: #028ccf;
  border-radius: 5px;
  padding: 0px 5px;
}

.button-div {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px 0px;
}

.button-div button,
.team .input-div button {
  width: 250px;
  height: 36px;
  background-color: #028ccf;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 2em;
}

.user-profile .gender {
  display: inherit;
  justify-content: space-around;
  width: 100%;
}

.user-profile .gender div {
  position: relative;
  float: left;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 30px;
}

.user-profile .team.team-grunden .gender {
  justify-content: center;
}

.user-profile .team.team-grunden .gender div {
  margin: 0px 20px;
}

.user-profile .gender div input {
  width: 15px;
  height: 15px;
}

.user-profile .gender div label {
  margin-bottom: 0px;
  margin-left: 5px;
}

.user-profile .team.team-grunden span {
  width: 100%;
  text-align: center;
  position: relative;
  float: left;
  font-size: 18px;
}

.user-profile .team.team-grunden span.title {
  font-size: 35px;
}

.user-popup {
  position: fixed;
  width: 0;
  height: 0;
  z-index: 9999;
  overflow: hidden;
}

.user-popup.active {
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.5)
}

.user-popup.active .popup-box {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  width: 400px;
  height: auto;
  padding: 30px;
}

.user-popup.active .popup-box span.text {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  font-size: 20px;
}

.user-popup.active .popup-box .popup-buttons {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.user-popup.active .popup-box .popup-buttons span {
  position: relative;
  float: left;
  width: 100px;
  height: 40px;
  border: 0;
  background-color: #0083d3;
  border-radius: 2em;
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  cursor: pointer;
}

.gender input {
  display: none;
}

input:disabled {
  cursor: no-drop;
}

.radioButton {
  width: 20px;
  height: 20px;
  border: 1px solid #0083d3
}

.radioButton.active {
  padding: 3px;
}

.radioButton.active .circle {
  height: 100%;
  width: 100%;
  background-color: #0083d3;
  border-radius: 50%;
  margin: 0 !important;
}

.radioButton.active .circle-light {
  height: 100%;
  width: 100%;
  background-color: #9edaff;
  border-radius: 50%;
  margin: 0 !important;
}

.submission .submit-button,
.question-wrapper .submit-button {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 30px
}

.submission .submit-button button,
.question-wrapper .submit-button button {
  position: relative;
  width: 200px;
  height: 30px;
  color: #fff;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  border-radius: 2em;
  border: none;
  line-height: 10px;
  cursor: pointer;
  outline: none;
}




.chatmenu {
  /* width: 50%; */
  position: relative;
  width: 300px !important;
  /* height: 30px; */
  color: #fff;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%) !important;
  border-radius: 2em;
  border: none;
  line-height: 10px;
  cursor: pointer;
  margin-left: 33%;
}

h1,
h2,
h3,
h4,
h5,
h6,
.h1,
.h2,
.h3,
.h4,
.h5,
.h6 {
  font-weight: 600 !important;
}

a,
span,
h1,
p {
  font-weight: 600;
}

.grayout {
  opacity: 0.6;
  /* Real browsers */
  filter: alpha(opacity=60);
  /* MSIE */
}

.CodeMirror {
  height: 100% !important;
}


* {
	box-sizing: border-box;
	outline: 0;
	/* font-family: "Open Sans",sans-serif !important; */
	padding: 0px;
	margin: 0px;
}

body {
	position: relative;
}

body:not(.CodeMirror) {
	font-family:"Open Sans", sans-serif;
}

h1,
h2,
h3,
h4 {
	margin:0;
}

img {
	max-width: 100%;
}

p {
	margin: 0;
	padding: 0;
}

a {}
a:focus,
a:after,
a:before,
a:visited,
a:hover {
	text-decoration: none;
	cursor: pointer;
}




.toggle {
  position: relative;
  display: block;
  width: 40px;
  height: 20px;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;
  transform: translate3d(0, 0, 0); }
  .toggle:before {
    content: "";
    position: relative;
    top: 3px;
    left: 3px;
    width: 34px;
    height: 14px;
    display: block;
    background: #9A9999;
    border-radius: 8px;
    transition: background .2s ease; }
  .toggle span {
    position: absolute;
    top: 0;
    left: 0;
    width: 20px;
    height: 20px;
    display: block;
    background: white;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(154, 153, 153, 0.5);
    transition: all .2s ease; }
    .toggle span:before {
      content: "";
      position: absolute;
      display: block;
      margin: -18px;
      width: 56px;
      height: 56px;
      background: rgba(79, 46, 220, 0.5);
      border-radius: 50%;
      transform: scale(0);
      opacity: 1;
      pointer-events: none; }

input:checked + .toggle:before {
  background: #947ADA; }

input:checked + .toggle span {
  background: #4F2EDC;
  transform: translateX(20px);
  transition: all 0.2s cubic-bezier(0.8, 0.4, 0.3, 1.25), background 0.15s ease;
  box-shadow: 0 3px 8px rgba(79, 46, 220, 0.2); }
  input:checked + .toggle span:before {
    transform: scale(1);
    opacity: 0;
    transition: all .4s ease; }

.center {
  position: absolute;
  top: calc(50% - 10px);
  left: calc(50% - 20px); }

.starting_soon {
    color: #0083d3;
    margin-top: 30px;
    font-size: 3em;
    font-weight: bold;
}

.follow-us {
    color: #0083d3;
    font-size: 2em;
    font-weight: bold;
    margin-right: 15px;
}

.follow-us-div {
    margin-top: 20px;
    display: inline-flex;
}

.follow-us-logo {
    margin: 0px 15px;
    width: 40px;
    height: 40px;
}

a,
abbr,
acronym,
address,
applet,
article,
aside,
audio,
b,
big,
blockquote,
body,
canvas,
caption,
center,
cite,
code,
dd,
del,
details,
dfn,
div,
dl,
dt,
em,
embed,
fieldset,
figcaption,
figure,
footer,
form,
h1,
h2,
h3,
h4,
h5,
h6,
header,
hgroup,
html,
i,
iframe,
img,
ins,
kbd,
label,
legend,
li,
mark,
menu,
nav,
object,
ol,
output,
p,
pre,
q,
ruby,
s,
samp,
section,
small,
span,
strike,
strong,
sub,
summary,
sup,
table,
tbody,
td,
tfoot,
th,
thead,
time,
tr,
tt,
u,
ul,
var,
video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline
}

article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
    display: block
}

body {
    line-height: 1.5
}

ol,
ul {
    list-style: none
}

b,
strong {
    font-size: 100%;
    font: inherit;
    font-weight: bold
}

blockquote,
q {
    quotes: none
}

blockquote:after,
blockquote:before,
q:after,
q:before {
    content: '';
    content: none
}

table {
    border-collapse: collapse;
    border-spacing: 0
}

* {
    box-sizing: border-box;
    outline: 0;
    -webkit-tap-highlight-color: rgba(255, 255, 255, 0)
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOXOhpKKSTj5PW.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 700;
    src: local("Open Sans Bold"), local("OpenSans-Bold"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN7rgOUuhpKKSTjw.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOXOhpKKSTj5PW.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UNirkOUuhpKKSTjw.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN_r8OXOhpKKSTj5PW.woff2) format("woff2");
    unicode-range: U+0100-024F, U+0259, U+1E00-1EFF, U+2020, U+20A0-20AB, U+20AD-20CF, U+2113, U+2C60-2C7F, U+A720-A7FF
}

@font-face {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 300;
    src: local("Open Sans Light"), local("OpenSans-Light"), url(https://fonts.gstatic.com/s/opensans/v15/mem5YaGs126MiZpBA-UN_r8OUuhpKKSTjw.woff2) format("woff2");
    unicode-range: U+0000-00FF, U+0131, U+0152-0153, U+02BB-02BC, U+02C6, U+02DA, U+02DC, U+2000-206F, U+2074, U+20AC, U+2122, U+2191, U+2193, U+2212, U+2215, U+FEFF, U+FFFD
}

html {
    font-size: 16px
}

html .womix__backgroundcliptext .womix__gradient {
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
}

html body main>section .frame-layout h1.womix__gradient {
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 3.5vw;
}

html body main>section .frame-layout h2.womix__gradient {
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-size: 2.8vw;
}

html.womix__no-backgroundcliptext .womix__gradient {
    background: 0 0;
    color: #0083d3
}

html.womix__no-backgroundcliptext body main>section.womix__horizontal-module-small .module-wrapper .womix__image-wrapper video {
    width: 100%
}

.womix__button {
    margin-top: 25px;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: 5px 35px;
    border: 2px solid #fff;
    border-radius: 20px;
    transition: all .4s cubic-bezier(.445, .05, .55, .95);
    cursor: pointer;
    height: 35px;
    min-width: 125px
}

.womix__button a,
.womix__button button,
.womix__button form,
.womix__button input,
.womix__button p,
.womix__button span {
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: #fff;
    padding: 0;
    margin: 0 !important;
    min-width: 0;
    border: none;
    outline: 0;
    background: 0 0;
    background-color: transparent;
    vertical-align: text-top;
    transition: color .4s cubic-bezier(.445, .05, .55, .95);
    text-decoration: none
}

.womix__button * {
    line-height: 1em;
    height: 1em
}

.womix__button:after {
    content: '';
    position: absolute
}

@media screen and (min-width:813px) {
    .womix__button:hover {
        background: #fff
    }

    .womix__button:hover a,
    .womix__button:hover button,
    .womix__button:hover form,
    .womix__button:hover input,
    .womix__button:hover p,
    .womix__button:hover span {
        color: #0083d3
    }
}

::-webkit-media-controls-panel {
    display: none !important;
    -webkit-appearance: none
}

::-webkit-media-controls-play-button {
    display: none !important;
    -webkit-appearance: none
}

::-webkit-media-controls-start-playback-button {
    display: none !important;
    -webkit-appearance: none
}

.slick-slider {
    position: relative;
    display: block;
    box-sizing: border-box;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    user-select: none;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent
}

.slick-list {
    position: relative;
    overflow: hidden;
    display: block;
    margin: 0;
    padding: 0
}

.slick-list:focus {
    outline: 0
}

.slick-list.dragging {
    cursor: pointer;
    cursor: hand
}

.slick-slider .slick-list,
.slick-slider .slick-track {
    transform: translate3d(0, 0, 0)
}

.slick-track {
    position: relative;
    left: 0;
    top: 0;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.slick-track:after,
.slick-track:before {
    content: "";
    display: table
}

.slick-track:after {
    clear: both
}

.slick-loading .slick-track {
    visibility: hidden
}

.slick-slide {
    float: left;
    height: 100%;
    min-height: 1px;
    display: none
}

[dir=rtl] .slick-slide {
    float: right
}

.slick-slide img {
    display: block
}

.slick-slide.slick-loading img {
    display: none
}

.slick-slide.dragging img {
    pointer-events: none
}

.slick-initialized .slick-slide {
    display: block
}

.slick-loading .slick-slide {
    visibility: hidden
}

.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent
}

.slick-arrow.slick-hidden {
    display: none
}

/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
    padding: 15px 15px;
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: .3s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    overflow: visible
}

.hamburger:hover {
    opacity: .7
}

.hamburger-box {
    width: 40px;
    height: 24px;
    display: inline-block;
    position: relative
}

.hamburger-inner {
    display: block;
    top: 50%;
    margin-top: -2px
}

.hamburger-inner,
.hamburger-inner::after,
.hamburger-inner::before {
    width: 40px;
    height: 4px;
    background-color: #000;
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: .3s;
    transition-timing-function: ease
}

.hamburger-inner::after,
.hamburger-inner::before {
    content: "";
    display: block
}

.hamburger-inner::before {
    top: -10px
}

.hamburger-inner::after {
    bottom: -10px
}

.hamburger--elastic .hamburger-inner {
    top: 2px;
    transition-duration: .55s;
    transition-timing-function: cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic .hamburger-inner::before {
    top: 10px;
    transition: opacity .25s .55s ease
}

.hamburger--elastic .hamburger-inner::after {
    top: 20px;
    transition: transform .55s cubic-bezier(.68, -.55, .265, 1.55)
}

.hamburger--elastic.is-active .hamburger-inner {
    transform: translate3d(0, 10px, 0) rotate(135deg);
    transition-delay: .15s
}

.hamburger--elastic.is-active .hamburger-inner::before {
    transition-delay: 0s;
    opacity: 0
}

.hamburger--elastic.is-active .hamburger-inner::after {
    transform: translate3d(0, -20px, 0) rotate(-270deg);
    transition-delay: .15s
}

html {
    width: 100vw
}

html body {
    display: flex;
    flex-direction: column;
    width: inherit;
    min-height: inherit;
    overflow-x: hidden
}

html body.no-scroll {
    overflow: hidden
}

html body h1,
html body h2,
html body h3,
html body h4,
html body li,
html body p {
    max-width: 100%
}

html body .womix__members_overlay {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    opacity: 0;
    background-color: transparent;
    z-index: 4;
    pointer-events: none;
    transition: all .6s cubic-bezier(.175, .885, .32, 1.275)
}

html body .womix__members_overlay .overlay_content {
    padding: 10px;
    border: 2px solid #fff;
    border-radius: 10px;
    transition: all .6s cubic-bezier(.6, -.28, .735, .045);
    background-color: #0083d3;
    color: #fff
}

html body .womix__members_overlay .overlay_content.authentication>ul {
    position: relative;
    display: flex;
    justify-content: space-between
}

html body .womix__members_overlay .overlay_content.authentication>ul li {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 48%;
    height: 50px;
    cursor: pointer;
    border: 2px solid #0083d3;
    transition: all .4s cubic-bezier(.445, .05, .55, .95);
    border-radius: 5px
}

html body .womix__members_overlay .overlay_content.authentication>ul li p {
    position: relative
}

html body .womix__members_overlay .overlay_content.authentication>ul li p:after {
    position: absolute;
    content: '';
    width: 6px;
    height: 6px;
    border: 2px solid #fff;
    border-left: none;
    border-bottom: none;
    transform: rotate(45deg);
    right: -12px;
    top: calc(50% - 3px);
    opacity: 0;
    transition: all .4s cubic-bezier(.445, .05, .55, .95)
}

html body .womix__members_overlay .overlay_content.authentication>ul li:hover:not(.active) p:after {
    opacity: 1
}

html body .womix__members_overlay .overlay_content.authentication>ul li.active {
    border-color: #fff
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms {
    position: relative;
    padding: 20px
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div {
    position: absolute;
    top: 0;
    left: 0;
    opacity: 0;
    pointer-events: none
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div.active {
    opacity: 1;
    position: relative;
    pointer-events: all;
    transition: opacity .4s cubic-bezier(.445, .05, .55, .95)
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div form .success-response {
    display: none;
    position: relative;
    background-color: transparent;
    opacity: 1;
    top: auto;
    left: auto;
    height: auto
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div form .womix__members_reset {
    cursor: pointer
}

html body .womix__members_overlay .overlay_content.authentication .womix__members_forms>div .reset-password-form {
    display: none;
    margin-top: 20px
}

html body .womix__members_overlay * {
    pointer-events: none
}

html body .womix__members_overlay.active {
    opacity: 1;
    pointer-events: all;
    background-color: #0083d3
}

html body .womix__members_overlay.active.transparent {
    background-color: rgba(255, 255, 255, .8)
}

html body .womix__members_overlay.active * {
    pointer-events: all
}

html body .info-layer {
    background: #fff;
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    height: 70px;
    display: flex;
    justify-content: center;
    transition: .3s cubic-bezier(.445, .05, .55, .95);
    z-index: 11;
    align-items: center
}

html body .info-layer .bwki-logo {
    height: 100%;
    display: flex;
    align-items: center;
    padding: 10px 0
}

@media screen and (max-width:812px) {
    html body .info-layer .bwki-logo {
        display: none
    }
}

html body .info-layer .bwki-logo h1 {
    padding-left: 4px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 1em
}

html body .info-layer .bwki-logo h1 span {
    font-weight: 600
}

html body .info-layer .bwki-logo .womix__image-wrapper {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    height: 100%
}

html body .info-layer .bwki-logo .womix__image-wrapper div {
    height: 100%;
    margin-right: 25px
}

html body .info-layer .bwki-logo .womix__image-wrapper div img {
    display: block;
    max-height: 50px
}

html body .info-layer .bwki-logo .womix__image-wrapper div:first-of-type {
    max-height: 40px
}

html body .info-layer .bwki-logo .womix__image-wrapper div:first-of-type img {
    max-height: 40px
}

html body .info-layer .womix__members {
    height: 100%;
    display: none;
    align-items: center;
    justify-content: center;
    margin: 0 10px 0 auto
}

html body .info-layer .womix__members .womix__members-content {
    height: 50px
}

html body .info-layer .womix__members .womix__members-content .womix__members_icon {
    height: 100%;
    cursor: pointer
}

html body .info-layer .womix__members .womix__members-content .womix__members_icon img {
    height: 100%;
    width: auto
}

html body .info-layer .additional-icons {
    height: 100%;
    position: absolute;
    right: 10px;
    top: 0;
    display: flex;
    align-items: center;
    justify-content: center
}

html body .info-layer .additional-icons .womix__image-wrapper {
    height: 100%
}

html body .info-layer .additional-icons .womix__image-wrapper a {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-decoration: none
}

html body .info-layer .additional-icons .womix__image-wrapper a img {
    height: 50%;
    width: auto
}

html body .info-layer .additional-icons .womix__image-wrapper a span {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    color: #0083d3;
    font-size: 1em
}

html body .info-layer .additional-icons .womix__countdown {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    font-size: 2em
}

@media (max-width:52em) {
    html body .info-layer .additional-icons .womix__countdown {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body .info-layer .additional-icons .womix__countdown {
        font-size: 3em
    }
}

html body .info-layer.scrolling {
    box-shadow: 1px 10px 48px 1px rgba(0, 0, 0, .5);
    transition: .3s cubic-bezier(.445, .05, .55, .95);
    background: #fff;
    height: 70px
}

html body nav {
    position: fixed;
    width: 30%;
    height: 100vh;
    min-width: 550px;
    z-index: 9999;
    pointer-events: none
}

html body nav .hamburger {
    z-index: 14;
    position: absolute;
    cursor: pointer;
    left: 0;
    top: 0;
    height: 70px;
    pointer-events: visible
}

html body nav .hamburger>span>span {
    background-color: #0083d3
}

html body nav .hamburger>span>span:after,
html body nav .hamburger>span>span:before {
    background-color: #0083d3
}

html body nav canvas {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0
}

html body nav ul {
    position: absolute;
    z-index: 12;
    height: inherit;
    display: flex;
    flex-flow: column;
    justify-content: flex-start;
    align-items: center;
    width: calc(100% - 150px);
    padding-top: 10vh
}

@media screen and (max-width:812px) {
    html body nav ul {
        padding-top: 15vh
    }
}

html body nav ul li {
    cursor: pointer;
    padding: 10px;
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 0, 0);
}

html body nav.is-active ul li {
    opacity: 0;
    transform: matrix(1, 0, 0, 1, 20, 0);
}

html body nav ul li:first-of-type {
    margin-bottom: 8vh
}

@media screen and (max-width:320px) {
    html body nav ul li:first-of-type {
        padding-left: 20px;
        margin-bottom: 5vh
    }
}

html body nav ul li a,
html body nav ul li p {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 3vw;
    line-height: 1.2;
    text-transform: uppercase;
    text-decoration: none
}

@media (max-width:50em) {

    html body nav ul li a,
    html body nav ul li p {
        font-size: 1.5em
    }
}

@media (min-width:83.33333em) {

    html body nav ul li a,
    html body nav ul li p {
        font-size: 2.5em
    }
}

html body nav ul li .bwki-logo {
    height: 100%;
    display: flex;
    flex-flow: column nowrap;
    justify-content: flex-end;
    align-items: center;
    padding-bottom: 5px
}

html body nav ul li .bwki-logo p {
    padding-left: 4px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 1em;
    margin-bottom: 10px
}

html body nav ul li .bwki-logo p span {
    font-weight: 600
}

html body nav ul li .bwki-logo .womix__image-wrapper:first-of-type {
    width: 135px;
    margin-bottom: 10px
}

html body nav ul li .bwki-logo .womix__image-wrapper:last-of-type {
    display: flex;
    flex-direction: row;
    align-items: flex-end
}

html body nav ul li .bwki-logo .womix__image-wrapper:last-of-type img {
    width: 30px
}

html body nav ul li .bwki-logo .womix__image-wrapper:last-of-type p {
    margin: 0 0 0 10px;
    font-size: .8em
}

@media screen and (max-width:320px) {
    html body nav ul li .bwki-logo .womix__image-wrapper:last-of-type p {
        font-size: .8em
    }
}

html body nav ul li .bwki-logo .womix__image-wrapper img {
    display: block;
    width: 100%
}

@media screen and (max-width:600px) {
    html body nav {
        min-width: 100vw
    }
}

html body nav.is-active {
    pointer-events: visible
}

html body nav.scrolling #hamburger {
    transition: top .3s cubic-bezier(.445, .05, .55, .95)
}

html body .womix__scroll-to-top {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: #fff;
    position: fixed;
    right: 10px;
    bottom: 10px;
    z-index: 18;
    box-shadow: 1px 10px 48px 1px rgba(0, 0, 0, .5);
    opacity: 0;
    transition: opacity .3s;
    cursor: pointer
}

html body .womix__scroll-to-top:before {
    content: '';
    position: absolute;
    width: 25%;
    height: 25%;
    border: 2px solid #0083d3;
    border-left: none;
    border-bottom: none;
    transform: rotate(-45deg);
    top: 21px;
    left: 17px
}

html body .womix__scroll-to-top.active {
    opacity: 1;
    transition: opacity .3s
}

html body header {
    padding-top: 100px;
    min-height: 100vh;
    background: #fff;
    position: relative
}

html body header .womix__header-content {
    display: flex;
    justify-content: center;
    align-items: flex-end
}

@media screen and (max-width:812px) {
    html body header .womix__header-content {
        flex-direction: column;
        align-items: center
    }
}

html body header .womix__header-content .headline-cta-wraper {
    margin-left: 30px;
    padding: 10px
}

@media screen and (max-width:812px) {
    html body header .womix__header-content .headline-cta-wraper {
        text-align: center;
        margin-left: 0
    }
}

html body header .womix__header-content .headline-cta-wraper h2 {
    z-index: 1;
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 45px;
    font-size: 5vw;
    line-height: 1.2
}

@media (max-width:30em) {
    html body header .womix__header-content .headline-cta-wraper h2 {
        font-size: 1.5em
    }
}

@media (min-width:90em) {
    html body header .womix__header-content .headline-cta-wraper h2 {
        font-size: 4.5em
    }
}



html body header .womix__header-content .headline-cta-wraper .womix__button {
    color: #fff;
    background: #0083d3;
    padding: 5px;
    padding: 22px 27px;
    border-radius: 30px;
    border: 3px solid #0083d3
}

html body header .womix__header-content .headline-cta-wraper .womix__button:hover {
    background: #fff;
    color: #0083d3
}

html body header .womix__header-content .womix__image-wrapper {
    max-height: 50vh;
    width: auto;
    max-width: 600px
}

@media screen and (max-width:1280px) {
    html body header .womix__header-content .womix__image-wrapper {
        max-width: 350px;
        max-height: 40vh
    }
}

@media screen and (max-width:812px) {
    html body header .womix__header-content .womix__image-wrapper {
        max-height: 30vh
    }
}

@media screen and (max-width:414px) {
    html body header .womix__header-content .womix__image-wrapper {
        max-height: 30vh
    }
}

html body header .womix__header-content .womix__image-wrapper img {
    max-height: inherit;
    max-width: 100%;
    display: block;
    margin-left: auto
}

@media screen and (max-width:414px) {
    html body header .womix__header-content .womix__image-wrapper img {
        margin: 0 auto
    }
}

html body header .womix__header-graphic {
    position: absolute;
    bottom: 0;
    overflow-x: hidden;
    width: 100%;
    height: 30%
}

html body header .womix__header-graphic svg {
    filter: drop-shadow(rgba(0, 0, 0, .2) 0 0 8px);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 110%
}

html body header .womix__header-graphic svg.header_graphic-layer-1 {
    left: -15%;
}

html body header .womix__header-graphic svg.header_graphic-layer-1 polygon {
    fill: #15e4ae
}

html body header .womix__header-graphic svg.header_graphic-layer-2 {
    left: 10%;
}

html body header .womix__header-graphic svg.header_graphic-layer-2 polygon {
    fill: #0cbabe
}

html body header .womix__header-graphic svg.header_graphic-layer-3 {
    left: -5%;
}

html body header .womix__header-graphic svg.header_graphic-layer-3 polygon {
    fill: #0083d3
}

@media screen and (max-width:790px) {
    html body header {
        padding-top: 90px;
        max-height: 100vh;
        min-height: 80vh
    }
}

html body main {
    padding-top: 0;
    margin-top: -1px;
    z-index: 2;
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    flex-shrink: 0;
    width: 100%;
    float: left;
}

html body main>section {
    max-width: 1280px;
    margin: 3rem auto 0 auto
}

html body main>section .womix__intro-text {
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    margin: 50px auto 0 auto;
    justify-content: space-between;
    position: relative;
    align-items: center;
    padding: 0 15px;
    max-width: unset
}

h1.intro-header {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    /* font-size: 36px; */
    font-size: 4vw;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    text-align: center;
}

html body main>section .womix__intro-text .inner-wrap {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    width: 100%;
    max-width: 1280px;
    margin: auto;
    z-index: 99;
}

html body main>section.intro-container {
    align-items: center;
    align-content: center;
    margin-bottom: 12rem;
}

html body main>section .frame-layout {
    width: 96%;
    background: #fff;
    padding: 75px 70px;
    margin: auto;
    margin-top: 100px;
    box-sizing: border-box;
    box-shadow: 0 3px 95px -11px rgb(0 0 0 / 75%);
    text-align: justify;
    align-self: center;
    align-items: center;
    align-content: center;
    text-align: center;
}

html body span.highlighter {
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
}

html body span.infos-highlighter {
    background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
    -webkit-background-clip: text;
    color: transparent;
    font-weight: bold;
    font-size: x-large;
}

@media screen and (max-width:812px) {
    html body main>section .womix__intro-text .inner-wrap {
        flex-direction: column
    }
}

html body main>section .womix__intro-text .inner-wrap .womix__video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-height: 100vh;
    max-width: 100%;
    overflow: hidden;
    width: 50%
}

@media screen and (max-width:414px) {
    html body main>section .womix__intro-text .inner-wrap .womix__video-wrapper {
        max-height: 100vh
    }
}

@media screen and (max-width:812px) {
    html body main>section .womix__intro-text .inner-wrap .womix__video-wrapper {
        width: 100%
    }
}

html body main>section .womix__intro-text .inner-wrap .womix__video-wrapper>div {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%
}

html body main>section .womix__intro-text .inner-wrap .womix__video-wrapper>div iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.womix__video-wrapper>div video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

html body main>section .womix__intro-text .inner-wrap .text-wrapper {
    width: 55%;
    padding-left: 15px
}

html body main>section .womix__intro-text .inner-wrap .text-wrapper h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff
}

@media (max-width:48em) {
    html body main>section .womix__intro-text .inner-wrap .text-wrapper h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section .womix__intro-text .inner-wrap .text-wrapper h2 {
        font-size: 4.5em
    }
}

html body main>section .womix__intro-text .inner-wrap .text-wrapper>p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    color: #fff
}

@media screen and (max-width:812px) {
    html body main>section .womix__intro-text .inner-wrap .text-wrapper>p {
        margin-top: 25px
    }
}

@media screen and (max-width:1280px) {
    html body main>section .womix__intro-text .inner-wrap .text-wrapper {
        width: 60%
    }
}

@media screen and (max-width:1024px) {
    html body main>section .womix__intro-text .inner-wrap .text-wrapper {
        width: 75%
    }
}

@media screen and (max-width:812px) {
    html body main>section .womix__intro-text .inner-wrap .text-wrapper {
        width: 100%;
        padding-left: 0
    }
}

html body main>section .womix__intro-text .womix__image-wrapper {
    overflow-x: hidden;
    overflow-y: hidden;
    opacity: .1;
    height: 75vh;
    right: 0;
    position: absolute;
    bottom: -30%;
    width: 100%;
    text-align: right;
    z-index: 9
}

html body main>section .womix__intro-text .womix__image-wrapper img {
    height: 100%
}

html body main>section.womix__horizontal-module {
    padding: 50px 0 50px;
    background: #fff;
    width: 100%;
    max-width: none;
    position: relative;
    box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75)
}

html body main>section.womix__horizontal-module.faq {
    padding: 90px 0 50px;
    background: #fff;
    width: 100%;
    max-width: none;
    position: relative;
    box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75);
    margin-top: 18rem;
}

html body main>section.womix__horizontal-module.faq:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-left: 100vw solid transparent;
}

html body main>section.womix__horizontal-module:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-left: 100vw solid transparent
}

html body main>section.womix__horizontal-module:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -99px;
    width: 0;
    height: 0;
    border-top: 101px solid #fff;
    border-left: 100vw solid transparent
}

html body main>section.womix__horizontal-module .womix__text-module {
    display: flex;
    flex-flow: row wrap;
    max-width: 1280px;
    margin: auto;
    padding: 0 20px;
    justify-content: space-between
}

.womix__text-module .info_main {
    min-height: 60rem;
}



.info_main .info-details {
    display: grid;
    grid-template-columns: 2fr 4fr;
    grid-column-gap: 5%;
    column-gap: 5%;
    -webkit-column-gap: 5%;
    -moz-column-gap: 5%;
    grid-row-gap: 4rem;
    row-gap: 4rem;
    margin: 2rem 4rem 2rem 0;

}

.info-details .tile-image {
    /* margin: 1rem; */
    display: flex;
    align-self: center;
    height: 200px;
    margin-left: 50px;

}

.info_main .info-details p {
    margin: 0 !important;
    display: flex;
    align-self: center;
}

html body main>section.womix__horizontal-module .womix__text-module>div {
    width: 100%;
    padding: 20px
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module .womix__text-module>div {
        width: 100%
    }
}

html body main>section.womix__horizontal-module .womix__text-module>div h2 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center;
}

@media (max-width:48em) {
    html body main>section.womix__horizontal-module .womix__text-module>div h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__horizontal-module .womix__text-module>div h2 {
        font-size: 4.5em
    }
}

html body main>section.womix__horizontal-module .womix__text-module>div p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-top: 25px;
    color: #000;
    text-align: justify;
}

html body main>section.womix__quote-module {
    padding: 50px 0 50px;
    background: #fff;
    width: 100%;
    max-width: none;
    position: relative;
    box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75)
}

html body main>section.womix__quote-module .content-wrapper {
    padding: 0 15px;
    z-index: 1;
    display: flex;
    margin: 0 auto;
    flex-direction: row-reverse;
    justify-content: space-around;
    position: relative;
    align-items: center;
    max-width: 1280px
}

html body main>section.womix__quote-module .content-wrapper .text-wrapper {
    width: 80%
}

html body main>section.womix__quote-module .content-wrapper .text-wrapper h2 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    text-align: center
}

@media (max-width:48em) {
    html body main>section.womix__quote-module .content-wrapper .text-wrapper h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__quote-module .content-wrapper .text-wrapper h2 {
        font-size: 4.5em
    }
}

html body main>section.womix__quote-module .content-wrapper .text-wrapper p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-top: 25px;
    color: rgba(0, 0, 0, .5);
    text-align: center
}

@media screen and (max-width:750px) {
    html body main>section.womix__quote-module .content-wrapper .text-wrapper {
        width: 100%
    }
}

html body main>section.womix__quote-module .content-wrapper .womix__image-wrapper {
    width: 30%
}

html body main>section.womix__quote-module .content-wrapper .womix__image-wrapper img {
    width: 100%
}

@media screen and (max-width:750px) {
    html body main>section.womix__quote-module .content-wrapper .womix__image-wrapper {
        display: none
    }
}

html body main>section.womix__quote-module:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-left: 100vw solid transparent
}

html body main>section.womix__quote-module:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -99px;
    width: 0;
    height: 0;
    border-top: 100px solid #fff;
    border-left: 100vw solid transparent
}

html body main>section.womix__horizontal-module-small {
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: 1600px
}

.divRow>h2 .App-header>h2 {
    text-transform: uppercase;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 30px;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
}


@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small {
        align-items: flex-start
    }
}

html body main>section.womix__horizontal-module-small>h2 {
    text-transform: uppercase;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    color: #fff;
    margin: 100px auto 5vw auto
}

@media (max-width:48em) {
    html body main>section.womix__horizontal-module-small>h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__horizontal-module-small>h2 {
        font-size: 4.5em
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small>h2 {
        margin-bottom: 60px
    }
}

html body main>section.womix__horizontal-module-small .womix__video-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    max-width: 100%;
    overflow: hidden;
    width: 50%
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .womix__video-wrapper {
        max-height: 45vh
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .womix__video-wrapper {
        width: 100%
    }
}

html body main>section.womix__horizontal-module-small .womix__video-wrapper>div {
    position: relative;
    padding-bottom: 56.25%;
    width: 100%
}

html body main>section.womix__horizontal-module-small .womix__video-wrapper>div iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

html body main>section.womix__horizontal-module-small .module-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between
}

html body main>section.womix__horizontal-module-small .module-wrapper:not(:nth-child(2)) {
    margin-top: 250px
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(2) {
    margin-top: 100px
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile {
    padding: 50px 0;
    background: #fff;
    width: 61vw;
    position: relative;
    box-shadow: 0 3px 95px -11px rgba(0, 0, 0, .75)
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile {
        width: 60vw;
        max-width: 960px
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile {
        width: 90vw
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile {
        width: 100vw
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper {
    padding: 0 70px;
    z-index: 1;
    display: flex;
    flex-direction: row-reverse;
    justify-content: space-between;
    position: relative;
    align-items: center;
    max-width: 900px;
    margin: auto
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper {
    width: 100%
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper h3 {
    text-transform: uppercase;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase
}

@media (max-width:48em) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper h3 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper h3 {
        font-size: 4.5em
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-top: 25px;
    color: #000
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .tile .content-wrapper .text-wrapper {
        width: 100%
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper .womix__image-wrapper {
    width: 40%;
    display: flex;
    justify-content: center;
    align-items: center
}

html body main>section.womix__horizontal-module-small .module-wrapper .womix__image-wrapper svg {
    width: 70%;
    opacity: .1
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper .womix__image-wrapper {
        display: none
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) {
    align-self: flex-end;
    flex-direction: row-reverse
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-left: 60vw solid transparent
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:before {
        display: none
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:before {
        border-left: 90vw solid transparent
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:before {
        border-left: 100vw solid transparent
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -99px;
    width: 0;
    height: 0;
    border-top: 100px solid #fff;
    border-left: 60vw solid transparent
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:after {
        display: none
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:after {
        border-left: 90vw solid transparent;
        bottom: -99px
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(even) .tile:after {
        border-left: 100vw solid transparent
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:before {
    content: '';
    display: block;
    position: absolute;
    top: -99px;
    width: 0;
    height: 0;
    border-bottom: 100px solid #fff;
    border-right: 60vw solid transparent
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:before {
        display: none
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:before {
        border-right: 90vw solid transparent;
        top: -99px
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:before {
        border-right: 100vw solid transparent
    }
}

html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:after {
    content: '';
    display: block;
    position: absolute;
    bottom: -99px;
    width: 0;
    height: 0;
    border-top: 100px solid #fff;
    border-right: 60vw solid transparent
}

@media screen and (min-width:1280px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:after {
        display: none
    }
}

@media screen and (max-width:812px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:after {
        border-right: 90vw solid transparent
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__horizontal-module-small .module-wrapper:nth-child(odd) .tile:after {
        border-right: 100vw solid transparent
    }
}

html body main>section.womix__reminder-module {
    margin: 200px auto 200px auto;
    text-align: center
}

html body main>section.womix__reminder-module a {
    text-decoration: none;
    display: inline-block;
    align-items: center;
    justify-content: center;
    padding: 20px 25px;
    border: 3px solid #fff;
    background: 0 0;
    transition: .3s
}

@media screen and (max-width:450px) {
    html body main>section.womix__reminder-module a {
        padding: 20px 10px
    }
}

html body main>section.womix__reminder-module a h2 {
    display: inherit;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    text-transform: uppercase;
    color: #fff;
    text-align: center
}

@media (max-width:48em) {
    html body main>section.womix__reminder-module a h2 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__reminder-module a h2 {
        font-size: 4.5em
    }
}

html body main>section.womix__reminder-module a:hover {
    background: #0083d3;
    transition: .3s
}

html body main>section.womix__reminder-module>p {
    max-width: 812px;
    margin: 20px auto;
    text-align: center;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em
}

html body main>section.womix__reminder-module form {
    max-width: 450px;
    margin: auto
}

html body main>section.womix__reminder-module form .e-mail-input {
    margin-top: 25px;
    display: flex
}

html body main>section.womix__reminder-module form .e-mail-input #form1_email {
    padding-left: 10px;
    height: 50px;
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    border: 3px solid #fff;
    background: 0 0;
    border-right: none;
    color: #fff
}

html body main>section.womix__reminder-module form .e-mail-input ::placeholder {
    color: #fff;
    opacity: 1
}

html body main>section.womix__reminder-module form .e-mail-input input#form1_surname,
html body main>section.womix__reminder-module form .e-mail-input label#label_surname {
    background-color: transparent;
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
    overflow: hidden
}

html body main>section.womix__reminder-module form .e-mail-input button {
    position: relative;
    padding: 0;
    width: 80px;
    border: 3px solid #fff;
    cursor: pointer;
    height: 50px;
    background: 0 0;
    position: relative
}

html body main>section.womix__reminder-module form .e-mail-input button svg {
    height: 40px
}

html body main>section.womix__reminder-module form .e-mail-input button .svg-send,
html body main>section.womix__reminder-module form .e-mail-input button .svg-type {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    opacity: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0
}

html body main>section.womix__reminder-module form .e-mail-input button .svg-send.active,
html body main>section.womix__reminder-module form .e-mail-input button .svg-type.active {
    transition: .3s cubic-bezier(.445, .05, .55, .95);
    opacity: 1
}

html body main>section.womix__reminder-module form .e-mail-input button.is-active {
    transition: background .3s cubic-bezier(.445, .05, .55, .95);
    background: #0083d3
}

html body main>section.womix__reminder-module form .data-storage {
    display: flex;
    align-items: flex-start;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 16px;
    color: #fff;
    margin-top: 10px;
    position: relative
}

html body main>section.womix__reminder-module form .data-storage .checkbox {
    position: relative;
    height: 0;
    width: 0
}

html body main>section.womix__reminder-module form .data-storage .checkbox input {
    opacity: 0
}

html body main>section.womix__reminder-module form .data-storage .checkbox input:checked~.custom-checkbox {
    background: #0083d3;
    transition: .3s
}

html body main>section.womix__reminder-module form .data-storage .checkbox input:checked~.custom-checkbox:after {
    border-radius: 30px;
    background: #0083d3;
    position: absolute;
    width: 60px;
    height: 60px;
    opacity: 0;
    transform-origin: top left;
    transition: width .4s, height .4s, opacity .3s .2s
}

html body main>section.womix__reminder-module form .data-storage .checkbox .custom-checkbox {
    position: absolute;
    top: 0;
    left: 0;
    width: 30px;
    height: 30px;
    border: 3px solid #fff;
    border-radius: 50%;
    opacity: 1;
    transition: .3s;
    display: flex;
    justify-content: center;
    align-items: center
}

html body main>section.womix__reminder-module form .data-storage .checkbox .custom-checkbox:after {
    content: "";
    display: block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    opacity: 1;
    position: absolute;
    background: 0 0
}

html body main>section.womix__reminder-module form .data-storage label {
    z-index: 1;
    padding-left: 40px
}

html body main>section.womix__reminder-module form .data-storage label a {
    color: #fff
}

html body main>section.womix__reminder-module .success-response {
    position: fixed;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100vh;
    top: -100%;
    left: 0;
    opacity: 0;
    background-color: #0083d3;
    z-index: 10
}

@media screen and (max-width:736px) {
    html body main>section.womix__reminder-module .success-response {
        flex-direction: column
    }
}

html body main>section.womix__reminder-module .success-response.active {
    opacity: 1;
    top: 0;
    transition: top .2s cubic-bezier(.445, .05, .55, .95)
}

html body main>section.womix__reminder-module .success-response.animated {
    opacity: 1;
    top: 100%;
    transition: top .2s cubic-bezier(.445, .05, .55, .95)
}

html body main>section.womix__reminder-module .success-response p {
    margin-left: 30px;
    max-width: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    color: #fff
}

@media (max-width:52em) {
    html body main>section.womix__reminder-module .success-response p {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body main>section.womix__reminder-module .success-response p {
        font-size: 3em
    }
}

@media screen and (max-width:736px) {
    html body main>section.womix__reminder-module .success-response p {
        margin-left: 0;
        margin-top: 30px
    }
}

html body main>section.womix__legal {
    color: #fff;
    padding: 0 50px
}

@media screen and (max-width:812px) {
    html body main>section.womix__legal {
        padding: 0 5vw
    }
}

html body main>section.womix__legal h1 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    font-size: 5vw;
    line-height: 1.2;
    text-transform: uppercase;
    margin-bottom: 30px
}

@media (max-width:48em) {
    html body main>section.womix__legal h1 {
        font-size: 2.4em
    }
}

@media (min-width:90em) {
    html body main>section.womix__legal h1 {
        font-size: 4.5em
    }
}

@media screen and (max-width:414px) {
    html body main>section.womix__legal h1 {
        font-size: 1.3em
    }
}

html body main>section.womix__legal h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    margin-bottom: 20px
}

@media (max-width:52em) {
    html body main>section.womix__legal h2 {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body main>section.womix__legal h2 {
        font-size: 3em
    }
}

html body main>section.womix__legal h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 1.5em;
    line-height: 1.5em;
    margin-bottom: 15px
}

html body main>section.womix__legal a {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    color: #fff;
    word-break: break-all;
    white-space: pre-wrap;
    margin-bottom: 12px
}

html body main>section.womix__legal p {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    margin-bottom: 12px
}

html body main>section.womix__legal p a {
    font-size: 1em
}

html body main>section.womix__legal ul {
    padding-left: 25px;
    list-style-type: disc
}

html body main>section.womix__legal ul li {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em
}

html body main>section.womix__legal ul li a {
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-size: 1em
}

html body main>section.womix__legal b {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: #fff;
    font-size: 1em
}

html body main>section.womix__legal .womix__ga-opt-out {
    text-decoration: underline;
    cursor: pointer
}

html body main>section.womix__faq {
    display: flex;
    flex-direction: column;
    align-items: flex-start
}

html body main>section.womix__faq h3 {
    padding: 15px 30px 15px 0;
    position: relative;
    cursor: pointer;
    margin: 0
}

html body main>section.womix__faq h3:after {
    position: absolute;
    top: calc(50% - 5px);
    right: 5px;
    content: '';
    width: 8px;
    height: 8px;
    border-width: 2px;
    border-style: solid;
    border-color: #fff #fff transparent transparent;
    transform: rotate(45deg);
    transition: all .4s cubic-bezier(.445, .05, .55, .95)
}

html body main>section.womix__faq h3.active:after {
    top: calc(50% - 9px);
    transform: rotate(135deg)
}

html body main>section.womix__faq>section {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-bottom: 30px;
    width: 100%
}

html body main>section.womix__faq>section div {
    max-height: 0;
    overflow: hidden;
    width: 100%;
    transition: all .6s linear
}

html body main>section.womix__faq>section div.active {
    max-height: 1000px;
}

html body main>section.womix__faq>section div p {
    padding: 20px 10px
}

html body main>* {
    flex-shrink: 0
}

html body footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 100px;
    width: inherit;
    flex-shrink: 0;
    padding: 30px 0
}

html body footer>div {
    width: 100%
}

html body footer .contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px 5vw 60px
}

html body footer .contact h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    font-size: 1.7em !important;
    text-align: center;
    color: #fff;
    margin-bottom: 15px
}

@media (max-width:52em) {
    html body footer .contact h3 {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body footer .contact h3 {
        font-size: 3em
    }
}

html body footer .contact ul {
    display: flex;
    flex-flow: row wrap;
    justify-content: center
}

html body footer .contact ul li {
    width: 100%;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    text-align: center;
    color: #fff;
    padding: 5px
}

html body footer .contact ul li span {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: #fff;
    font-size: 1em !important
}

html body footer .contact ul li a {
    color: #fff
}

html body footer .contact ul li.half {
    width: auto;
    margin-top: 10px
}

@media screen and (max-width:812px) {
    html body footer .contact ul li.half {
        width: 100%
    }
}

html body footer .contact ul li.half:last-of-type {
    margin-left: 15px
}

@media screen and (max-width:812px) {
    html body footer .contact ul li.half:last-of-type {
        margin-left: 0
    }
}

html body footer .sponsor-section {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: flex-start;
    border-top: 3px solid #fff;
    border-bottom: 3px solid #fff;
    max-width: 1920px;
    padding: 20px 5vw
}

html body footer .sponsor-section>div {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    width: 28%;
    padding: 30px
}

@media screen and (max-width:1024px) {
    html body footer .sponsor-section>div {
        width: 48%
    }
}

@media screen and (max-width:812px) {
    html body footer .sponsor-section>div {
        padding: 0
    }
}

@media screen and (max-width:414px) {
    html body footer .sponsor-section>div {
        width: 100%
    }

    html body footer .sponsor-section>div:not(:last-of-type) {
        margin-bottom: 40px
    }
}

html body footer .sponsor-section>div h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 1.2em;
    line-height: 1em;
    color: #fff;
    color: #fff;
    margin-bottom: 20px
}

@media screen and (max-width:414px) {
    html body footer .sponsor-section>div h3 {
        width: 100%;
        text-align: center
    }
}

html body footer .sponsor-section>div .womix__image-wrapper {
    padding: 20px 0;
    width: 100%;
    max-width: 350px
}

@media screen and (max-width:812px) {
    html body footer .sponsor-section>div .womix__image-wrapper {
        padding: 10px 0
    }
}

html body footer .sponsor-section>div .womix__image-wrapper img {
    display: block;
    width: 100%;
    max-width: 100%
}

html body footer .sponsor-section>div .womix__image-wrapper h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    line-height: 1.2em
}

@media (max-width:52em) {
    html body footer .sponsor-section>div .womix__image-wrapper h3 {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body footer .sponsor-section>div .womix__image-wrapper h3 {
        font-size: 3em
    }
}

html body footer .sponsor-section>div .womix__image-wrapper h3 span {
    font-size: 20px;
    line-height: 24px
}

html body footer .sponsor-section>div .womix__image-wrapper a {
    text-decoration: none
}

html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:first-of-type {
    max-width: 100%
}

html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(2),
html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
    max-width: 47%
}

@media screen and (max-width:414px) {

    html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(2),
    html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
        width: 45%
    }
}

html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
    margin-top: .7vw
}

@media screen and (max-width:1024px) {
    html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
        margin-top: 1.3vw
    }
}

@media screen and (max-width:414px) {
    html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(3) {
        margin-top: 2.7vw
    }
}

html body footer .sponsor-section>div.further-sponsors .womix__image-wrapper:nth-of-type(4) {
    max-width: 65%
}

html body footer .sponsor-section>div.organisators {
    width: 30%;
    align-items: center;
    flex-direction: column;
}

@media screen and (max-width:1024px) {
    html body footer .sponsor-section>div.organisators {
        width: 100%
    }
}

html body footer .sponsor-section>div.organisators h3 {
    width: 100%;
    text-align: center;
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper {
    align-items: center;
    max-width: 450px
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(1) {
    width: auto;
    max-width: 100%
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(2) {
    width: auto;
    max-width: 100%
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) {
    width: auto;
    max-width: 100%
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) a {
    color: #fff
}

html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 24px;
    font-size: 2.5vw;
    line-height: 1.5em;
    font-size: 1.2em !important
}

@media (max-width:52em) {
    html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) h4 {
        font-size: 1.3em
    }
}

@media (min-width:120em) {
    html body footer .sponsor-section>div.organisators .womix__image-wrapper:nth-of-type(3) h4 {
        font-size: 3em
    }
}

html body footer .sponsor-section>div.cooperation {
    width: 30%;
    justify-content: center;
    align-items: center;
    margin: 0px auto 0;
    flex-direction: column;
}

html body footer .sponsor-section>div.cooperation h3 {
    width: 100%;
    text-align: center
}

@media screen and (max-width:812px) {
    html body footer .sponsor-section>div.cooperation {
        width: 80%
    }
}

@media screen and (max-width:414px) {
    html body footer .sponsor-section>div.cooperation {
        width: 100%
    }
}

html body footer .sponsor-section>div.cooperation .womix__image-wrapper {
    width: auto;
    padding: 0 20px;
    max-width: 350px;
    margin-top: 20px
}

html body footer .sponsor-section>div.cooperation .womix__image-wrapper:last-of-type {
    max-height: 110px
}

html body footer .sponsor-section>div.cooperation .womix__image-wrapper:last-of-type a {
    max-height: inherit
}

html body footer .sponsor-section>div.cooperation .womix__image-wrapper:last-of-type img {
    width: auto;
    max-height: inherit
}

.main-sponsors h3 {
    width: 100%;
    text-align: center;
}

html body footer .legal-section {
    padding: 50px 0;
    display: flex;
    justify-content: center;
    flex-wrap: wrap
}

html body footer .legal-section a {
    padding: 10px 20px;
    font-family: "Open Sans", sans-serif;
    font-weight: 300;
    font-size: 1.2em;
    line-height: 1.5em;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    text-align: center
}

@media screen and (max-width:750px) {
    html body footer .legal-section {
        flex-direction: column
    }
}

.impressionNew {
    display: block;
}

.divRow>ul {
    margin: 0px;
    padding: 15px 0px 15px 0px;
    width: 100%;
}

.divRow>ul>li {
    list-style-type: none;
    margin: 10px;
    padding: 5px;
    border-width: 2px;
    display: inline-block;
    vertical-align: top !important;
    /*width: 20em;*/
}

.itemDiv {
    margin: 0px;
    padding: 0px;
    height: 25em;
    width: 25em;
}

.App-header {
    height: 50px;
    padding: 10px 0px 10px 0px;
    /* width: 40%; */
    margin: 0px;
    text-align: center;
    text-transform: uppercase;
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 36px;
    line-height: 1.2;
    color: #fff;
}

.divRow {
    display: block;
    margin-left: calc((100% - 81.5em)/2);
    margin-right: calc((100% - 81.5em)/2);
}

.mainDiv {
    margin-top: 10em;
}


.item-header {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 25px;
    line-height: 1.2;
    color: #fff;
}

.item-sub-header {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 15px;
    line-height: 1.2;
}

.team-names {
    text-align: center;
    font-family: "Open Sans", sans-serif;
    font-weight: 700;
    font-size: 20px;
    line-height: 1.2;
    color: #fff;
}

@media screen and (max-width:81.5em) {
    .divRow {
        display: block;
        margin-left: calc((100% - 54.5em)/2);
        margin-right: calc((100% - 54.5em)/2);
    }
}

@media screen and (max-width:54.5em) {
    .divRow {
        display: block;
        margin-left: calc((100% - 27em)/2);
        margin-right: calc((100% - 27em)/2);
    }
}

#tempImg {
    height: 100%;
    width: 100%;
    display: block;
}

.pdfFrame {
    height: 22em;
    width: 25em;
    display: block;

}

.itemDiv-pdfRow {
    margin: 0px;
    padding: 0px;
    height: 30em;
    width: 25em;
}

a.verlinkung-intro {
    color: white;
    text-decoration: underline;
}

h1.infos-lehrende {
    margin-bottom: 3rem;
}

h2.infos-lehrende {
    margin-top: 5rem;
    margin-bottom: 2rem;
}

.infos-lehrende-content {
    font-size: 1.2em;
    text-align: justify !important;
    /* text-justify: auto; */

}

.infos-lehrende-content p {
    margin-top: 0;
    margin-bottom: 1rem;
}

.infos-lehrende-content .download-button-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
}

.infos-lehrende-content .download-button-wrapper a {
    width: 15rem;
    text-decoration: none;
    cursor: pointer;
    transition: all 0.1s linear;
    font-weight: bolder;
}

.infos-lehrende-content .download-button-wrapper a:hover {
    color: #fff;
    transform: scale(1.1);
}

.infos-lehrende-content .module1 {
    color: #0083d3;
    font-weight: 700;
}

.infos-lehrende-content .module2 {
    color: #0cbabe;
    font-weight: 700;
}

.infos-lehrende-content .module3 {
    color: #15e4ae;
    font-weight: 700;
}

.infos-lehrende-content ul {
    list-style: square inside;
}

.loader{
  margin: auto auto;
}

.lds-roller {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-roller div {
    animation: lds-roller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
  }
  .lds-roller div:after {
    content: " ";
    display: block;
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background: #FFF;
    margin: -4px 0 0 -4px;
  }
  .lds-roller div:nth-child(1) {
    animation-delay: -0.036s;
  }
  .lds-roller div:nth-child(1):after {
    top: 63px;
    left: 63px;
  }
  .lds-roller div:nth-child(2) {
    animation-delay: -0.072s;
  }
  .lds-roller div:nth-child(2):after {
    top: 68px;
    left: 56px;
  }
  .lds-roller div:nth-child(3) {
    animation-delay: -0.108s;
  }
  .lds-roller div:nth-child(3):after {
    top: 71px;
    left: 48px;
  }
  .lds-roller div:nth-child(4) {
    animation-delay: -0.144s;
  }
  .lds-roller div:nth-child(4):after {
    top: 72px;
    left: 40px;
  }
  .lds-roller div:nth-child(5) {
    animation-delay: -0.18s;
  }
  .lds-roller div:nth-child(5):after {
    top: 71px;
    left: 32px;
  }
  .lds-roller div:nth-child(6) {
    animation-delay: -0.216s;
  }
  .lds-roller div:nth-child(6):after {
    top: 68px;
    left: 24px;
  }
  .lds-roller div:nth-child(7) {
    animation-delay: -0.252s;
  }
  .lds-roller div:nth-child(7):after {
    top: 63px;
    left: 17px;
  }
  .lds-roller div:nth-child(8) {
    animation-delay: -0.288s;
  }
  .lds-roller div:nth-child(8):after {
    top: 56px;
    left: 12px;
  }
  @keyframes lds-roller {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
  
.main-wrapper {

  display: flex;
  flex-direction: column;
  justify-items: center;
  justify-self: center;
  width: 100%;
  margin-top: 3rem;
  min-height: 60vh;
}

.main-wrapper .message-prompt {
  width: 60%;
  max-width: 1200px;
  font-size: 1.25rem;
  /* font-weight: bold; */
  text-align: justify;
  margin: 1rem auto;
}

.main-wrapper .main-header {
  font-size: 2rem;
  font-weight: bold;
  margin: 0 auto;
}

.main-wrapper .ethics-sub-header {
  width: 80%;
  max-width: 900px;
  font-size: 1.25rem;
  font-weight: 600;
  text-align: left;
  display: flex;
  align-self: center;
}

.definition-wrapper-temp {
  display: flex;
  justify-content: center;
}

.definition-item-temp {
  display: flex;
  height: 2rem;
  width: 2rem;
  background: rgba(0, 0, 0, 0.075);
  justify-content: center;
  text-align: center;
  align-items: center;
  margin: 0.25rem 1rem;
  cursor: pointer;
}


.path-options {
  display: flex;
  flex-wrap: wrap;
  justify-self: center;
  justify-content: center;
  grid-gap: 5%;
  gap: 5%;
}

.path-option {
  /* background: rgba(0, 0, 0, 0.1); */
  margin: 1rem 2rem;
  padding: 0.5rem 1rem;
  font-weight: bold;
  color: black;
  cursor: pointer;
  width: 20rem;
}

.path-option:hover {
  transform: scale(1.2);
}

.path-option .path-option-title {
  font-size: 1.5rem;
}

.ethics-content-wrapper {
  display: grid;
  grid-template-rows: max-content;
  height: 100%;
  width: 100%;
  min-height: 60vh;
}

.panel-list {
  display: flex;
  justify-content: center;
}

ul.panel-list li {
  margin: 1rem;
  display: grid;
}

.panel-item-header,
ul.panel-list li img.panel-item-header {
  justify-self: center;
  font-size: 1.2rem;
  font-weight: 600;
  text-align: center;
}

.panel-item-img,
ul.panel-list li img.panel-item-img {
  height: 10rem;
  justify-self: center;
  margin: 1rem;
}

ul.panel-list li .panel-item-check {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  justify-self: center;
}


/*scenarios */

.scenario-wrapper {
  display: grid;
  width: 80%;
  max-width: 75rem;
  justify-self: center;
}

.scenario-intro-wrapper {
  display: grid;
  position: relative;
  width: 100%;
  padding: 1rem;
  grid-template-columns: 1fr 1fr;
  box-sizing: border-box;
}

.scenario-intro-header {
  display: grid;
  grid-template-columns: auto 1fr;
  grid-row: 1;
  grid-column: 1/span 2;
  margin: 0 1rem 2rem 1rem;
}

.scenario-intro-header .header-img {
  width: 15rem;
  display: flex;
  align-self: center;
}

.scenario-intro-header .header-title {
  grid-column: 2;
  display: flex;
  flex-direction: column;
  text-align: center;
  margin: 1rem 1rem 1rem 2rem;
}

.header-title h2 {
  font-weight: bold !important;
}

.header-title div {
  font-weight: 600;
  font-size: 1.25rem;
  text-align: left;
}

.header-title.sub-header-center div {
  text-align: center;
}


.scenario-intro-wrapper .scenario-info {
  display: flex;
  flex-direction: column;
  grid-row: 3;
  grid-column: 1;
  align-self: center;
}

.scenario-intro-wrapper .audio-wrapper {
  display: flex;
  align-items: center;
  margin: 0 1rem;
  font-size: 1.25rem;
  cursor: pointer;
  padding: 0.25rem 0;
  border-radius: 0.5rem;
  width: auto;
}

.scenario-intro-wrapper .audio-wrapper:hover {
  background: rgba(0, 0, 0, 0.025);
}

.scenario-intro-wrapper .audio-wrapper img {
  width: 2rem;
  border-radius: 1rem;
  padding: 1px;
  margin: 0 0.5rem;
}

.scenario-intro-wrapper .scenario-header {
  text-align: left;
  font-weight: bold;
  font-size: 1.25rem;
  padding: 0 1rem;
}

.scenario-intro-wrapper .scenario-desc {
  padding: 1rem;
  font-size: 1.2rem;
}

.scenario-intro-wrapper .scenario-img {
  grid-row: 3;
  grid-column: 2;
  display: flex;
  width: 100%;
  justify-content: center;
  align-content: center;
  padding: 1rem 0;
}

.scenario-intro-wrapper .scenario-img img {
  width: 80%;

}

.scenario-intro-wrapper button {
  grid-column: 1/ span 2;
  width: 10rem;
  justify-self: center;
  margin: 1rem;
}

.button-wrapper.scenarios-selection-actions,
.button-wrapper.scenarios-end-actions {
  display: flex;
  justify-content: space-around;
}

.button-wrapper.scenarios-selection-actions button {
  width: 20rem;
}

.button-wrapper.scenarios-end-actions button {
  width: 17.5rem;
}

.conversation-wrapper {
  display: grid;
  margin: 1rem;
}

.conversation-wrapper .conversation-message {
  background: rgba(0, 0, 0, 0.075);
  width: 80%;
  justify-self: center;
  padding: 2rem;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: 600;
}

.conversation-panel {
  display: flex;
  justify-self: center;
  justify-content: space-between;
}

.pros-cons-wrapper {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1rem;
  column-gap: 1rem;
}

.summary-list-header {
  margin: 0 1rem;
  font-size: 1.5rem;
  color: #AAA;
  font-weight: bold;

}

.summary-item {
  display: grid;
  /* flex-direction: column; */
  justify-self: center;
  margin: 1rem;
  font-size: 1rem;
}

.summary-desc {
  background: rgba(0, 0, 0, 0.075);
  border-radius: 1rem;
  padding: 1.5rem 2rem;
  font-weight: 600;
}

.summary-info {
  display: flex;
  max-width: max-content;
  justify-self: right;
  font-size: 1.2rem;
}

.actions {
  display: flex;
  justify-content: center;
  width: 100%;
  margin: 1rem 0;

}

.actions button {
  width: 10rem;
  margin: 0 1rem;
  padding: 0.5rem 1rem;
}

/* -------- */

.overlay-panel .section:first-child {
  padding-top: 0;
  font-size: 20px;
  text-align: justify;
}

.intro.ethics {
  margin: 2rem 0;
}

.intro.ethics p {
  text-align: justify;
}

.intro.ethics img {
  height: 20rem !important;
  display: flex;
  justify-self: center;
  margin: 0 auto !important;
}

.intro.intro.ethics img.spread-vertical {
  height: auto !important;
}

.intro.ethics img.interactive-svg-main {
  width: 100% !important;
  height: auto !important;
  margin: 0 auto !important;
}

.intro.ethics.columns-2 {
  display: grid;
  grid-template-columns: 1fr 1fr;
}


.intro.ethics.columns-2 img {
  grid-row: 1/span 10;
  grid-column: 2;
  height: auto !important;
  width: 90% !important;
}

.checklist-img {
  height: auto;
  width: 20rem;
  margin: auto;
  display: block;
  cursor: pointer !important;
}


/* --- */

.info-panel .svgfuture-wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.info-panel .svgfuture-wrapper .audio-wrapper {
  position: absolute;
  bottom: 0rem;
  right: -7.5rem;
  text-align: center;
  width: 7.5rem;
}

.audio-iframe-wrapper,
.info-panel .svgfuture-wrapper .audio-iframe-wrapper {
  display: flex;
  flex-direction: column;
}

.overlay-panel.include-audio .audio-iframe-wrapper {
  margin: 0 2rem;
}

.audio-iframe-wrapper .audio-title,
.info-panel .svgfuture-wrapper .audio-iframe-wrapper .audio-title {
  font-size: 1.25rem;
  margin: 1rem 1rem 0 1rem;
  font-weight: bold;
}

.info-panel .svgfuture-wrapper .audio-wrapper.bt-5 {
  margin-bottom: 5rem;
}

.info-panel .svgfuture-wrapper .audio-wrapper img {
  width: 7.5rem;
  height: auto;
  cursor: pointer;
}

.audio-iframe,
.info-panel .svgfuture-wrapper .audio-iframe {
  height: 5rem;
}

.overlay-panel .audio-wrapper {
  position: relative;
  height: 4rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
  top: -1.25rem;
  cursor: pointer;
}

.overlay-panel .audio-wrapper img {
  width: 2rem;
  border-radius: 50%;
  padding: 1px;
  margin: 0rem 1rem 0 0.5rem;
}

/* .info-panel .members-wrapper {} */

.info-panel .members-wrapper .member-speech-list {
  margin: 0 0 0rem 0;
  display: flex;
  justify-content: space-around;
  width: 100%;
}

.info-panel .members-wrapper .member-speech-list .speech-item {
  margin: 1rem 0.25rem;
  display: grid;
  width: 10rem;
  align-items: flex-end;
  grid-template-rows: 1fr auto;
}

.info-panel .members-wrapper .member-speech-list .speech-item .speech-header {
  font-size: 1rem;
  text-align: center;
  align-self: flex-start;
}

.info-panel .members-wrapper .member-speech-list .speech-item .speech-header span {
  font-weight: bold;
  display: block;
}

.info-panel .members-wrapper .member-speech-list .speech-item .img {
  height: 4rem;
  width: 4rem;
  justify-self: center;
  margin: 0.5rem 1rem;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 0.5rem;
}

.info-panel .members-wrapper .panel-info {
  font-size: 1.25rem;
}

.info-panel .members-wrapper .panel-info:first {
  margin-top: 2rem;
}

.intro-section .intro-wrapper .intro.ethics img.link-icon {
  height: 1em !important;
  display: inline-flex;
  padding: 0 0.5rem;
  cursor: pointer;
  margin: 0 !important;
  pointer-events: none;
}

.intro-section .intro-wrapper .intro.ethics img.link-icon:hover {
  background: rgba(0, 0, 0, 0.075)
}

.intro-section .intro-wrapper .intro.ethics .shortcut-wrapper {
  width: 100%;
  height: auto;
  display: flex;
  justify-content: space-around;
  align-content: center;
  max-width: 900px;
  margin: 2rem 0 1rem 0;
}

.intro-section .intro-wrapper .intro.ethics .shortcut-wrapper button {
  display: flex;
  /* min-width: 17.5rem; */
  height: 2.5rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #EEE;
  color: #999;
  font-size: 1.2rem;
  font-weight: bold;
  align-items: center;
  text-align: center;
}

.intro-section .intro-wrapper .intro.ethics .shortcut-wrapper button:hover {
  text-decoration: none;
  color: #333 !important;
  transform: scale(1.025);
}

.intro-section .intro-wrapper .intro.ethics .shortcut-wrapper a:hover {
  text-decoration: none;
}

/* .intro-section .intro-wrapper .intro.ethics .shortcut-wrapper button img.link-icon {
  pointer-events: none;
} */

/* *************************** */
/*    CONCLUSION: ETHIKRAT     */
/* *************************** */

.info-panel .members-wrapper .fields-container {
  margin: 0 0 0rem 0;
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.info-panel .members-wrapper .fields-container .field-item {
  margin: 1rem 0.25rem;
  width: 12rem;
  align-items: flex-end;
  grid-template-rows: 1fr auto;
  cursor: pointer;
}

.info-panel .members-wrapper .fields-container .field-item .field-header {
  font-size: 1.25rem;
  text-align: center;
  align-self: flex-start;
}

.info-panel .members-wrapper .fields-container .field-item .field-header span {
  font-weight: bold;
  display: block;
}

.info-panel .members-wrapper .fields-container .field-item .img-wrapper {
  height: 10rem;
  width: 10rem;
  justify-self: center;
  margin: auto;
  margin-bottom: 0.75rem;
  padding: 0.3rem;
  border-radius: 50%;
}

.info-panel .members-wrapper .fields-container .field-item img.img {
  justify-self: center;
  border-radius: 50%;
}


audio::-webkit-media-controls-play-button,
audio::-webkit-media-controls-panel {
  background-color: #a0276e;
  color: #a0276e;
}

audio::-webkit-media-controls-panel {
  background-color: blue !important;
}

.react-audio-player {
  width: '100%';
  height: '4rem';
  -webkit-tap-highlight-color: red
}


.video-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 500px;
  margin: 1.5rem 0;
}

.video-wrapper iframe {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.options-wrapper {
  display: flex;
  flex-direction: column;
  margin: 0 3rem;
}

.options-wrapper .options {
  display: flex;
  justify-content: center;
}

.options-wrapper .options-header {
  font-size: 1.25rem;
  display: flex;
  justify-content: center;

}

.options-wrapper .options.button-wrapper Button {
  width: 10rem;
  margin: 0 1rem
}



/* The container */
.checkbox-container {
  display: block;
  position: relative;
  padding-left: 0;
  margin-bottom: 0;
  cursor: pointer;
  font-size: 1.5rem;
  -webkit-user-select: none;
  user-select: none;
}

/* Hide the browser's default checkbox */
.checkbox-container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 2.5rem;
  background-color: #eee;
  border: 2px solid #777;
  border-radius: 0.25rem;
}

/* On mouse-over, add a grey background color */
.checkbox-container:hover input~.checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.checkbox-container input:checked~.checkmark {
  background-color: #00E3A7;
  border: 2px solid #00E3A7;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.checkbox-container input:checked~.checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.checkbox-container .checkmark:after {
  left: 0.75rem;
  top: 0.2rem;
  width: 0.8rem;
  height: 1.5rem;
  border: solid white;
  border-width: 0 5px 5px 0;
  transform: rotate(45deg);
}

.interactive-path {
  fill: transparent;
  cursor: pointer;
  pointer-events: all;
}

.interactive-path:hover {
  fill: #00E3A755;
  stroke: #00E3A7;
  stroke-width: 3px;
}

.background-image-style {
  display: flex;
  height: 4rem;
  grid-row: 1;
}

.overlay-panel-container .overlay-panel.with-img {
  grid-template-rows: 6rem 1fr auto;
}

.ethics-overlay-header {
  grid-row: 1;
  display: flex;
  margin: 0 3rem;
  height: -moz-fit-content;
  height: fit-content;
  align-self: center;
}

.ethics-overlay-header img {
  margin-right: 1rem;
}
.kischool {
  display: block;
  text-align: center !important;
  color: #15e4ae;
  position: absolute;
  bottom: 0px;
  font-size: 1.2em;
  width: 100%;
  font-weight: bold;
}

.content-tasks {
  position: relative;
  float: left;
  width: 98%;
  height: calc(calc(100vh - 66px) - 30vh);
  margin-top: 16px;
  margin-bottom: 30px;
  overflow-y: scroll;
}

.countdown {
  display: block;
  text-align: center !important;
  color: #15e4ae;
  position: absolute;
  bottom: 30px;
  width: 100%;
  font-size: 1.5em;
  font-weight: bold;
}

.countdown u {
  color: #15e4ae !important;
}

.countdown-timer {
  color: #028ccf;
}

.verlinkung {
  color: #0083d3;
  margin-bottom: 20px;
  text-align: center !important;
}

@font-face {
  font-family: "Font Awesome 5 Free" !important;
}

.fa,
.far,
.fas {
  font-family: "Font Awesome 5 Free" !important;
}

.radio-description {
  position: relative;
  float: left;
  padding-left: 10px;
  padding-right: 10px;
  width: 100%;
  margin-top: 5px;
  margin-bottom: 10px;
}

.radio-wrapper {
  position: relative;
  float: left;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  top: 35%;
  display: flex;
  width: 100%;
}

.radio-label {
  flex: 1 1 100%;
  text-align: center;
}

.radio-input {
  height: auto !important;
}


body {
  overflow-y: auto;
}

/* START OF LOGIN AND REGISTER*/
.page {
  position: relative;
  float: left;
  width: 100%;
  height: 100vh;
  overflow-y: scroll;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
}

.login {
  position: absolute;
  float: left;
  width: 420px;
  height: 420px;
  left: 50%;
  top: 50%;
  transition: 1s ease-in-out all .3s;
  transform: translate(-50%, -50%);
  border-radius: 10px;
  z-index: 100;
}

/* change for registration new fields */
.login.active {
  width: 800px;
  height: 1000px;
  max-height: 1200px;
  margin-top: 60px;
  margin-bottom: 60px;
}

.login.active.warning-active {
  height: 1000px;
}

.login.activeTeam {
  width: 720px;
  height: 1000px;
  max-height: 1100px;
  margin-bottom: 100px;
  margin-top: 200px;
}

.login.activeTeam.warning-active {
  height: 1100px;
}

.login .nav-login {
  position: relative;
  float: left;
  width: 100%;
  height: 70px;
  border-top-right-radius: 10px;
  border-top-left-radius: 10px;
}

.login .nav-login a.user-login {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  background-color: #028ccf;
  border-top-left-radius: 10px;
}

.change-password .login .nav-login a.user-login {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  cursor: auto;
}

.change-password .login .content-login .content-login-wrapper .input-wrapper {
  height: auto;
}

.change-password .login .content-login .content-login-wrapper .input-wrapper input {
  margin-bottom: 10px;
}

.change-password .login .content-login .content-login-wrapper .button-login {
  margin-top: 20px;
}

.login .nav-login a span {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  text-align: center;
  line-height: 63px;
  font-size: 20px;
  color: #fff;
}

.login .nav-login a.team-login {
  position: relative;
  float: left;
  width: 50%;
  height: 100%;
  background-color: #028ccf;
  border-top-right-radius: 10px;
}

.login .nav-login a.active span {
  color: #028ccf;
}

.login .nav-login a.active {
  background-color: #fff;
}

.login .content-login {
  position: relative;
  float: left;
  width: 100%;
  height: calc(100% + 0.5rem);
  overflow: hidden;
}

.login .content-login .content-login-wrapper {
  float: left;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0px;
  top: 0px;
  transition: 1.3s ease-in-out all;
  transform: translateY(-500%);
  background-color: #fff;
  padding: 0px 40px;
}

.confirm-user {
  position: relative;
  float: left;
  width: 100%;
  height: 100vh;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
}

.confirm-user h1 {
  position: absolute;
  float: left;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
}

.confirm-user a {
  position: absolute;
  float: left;
  width: 220px;
  min-width: -moz-fit-content;
  min-width: fit-content;
  height: 50px;
  padding: 0.25rem;
  top: 60%;
  left: 50%;
  transform: translate(-50%, -60%);
  border: none;
  border-radius: 2em;
  background-color: #028ccf;
  color: #fff;
  font-size: 21px;
  outline: none;
  cursor: pointer;
  text-align: center;
  line-height: 38px;
  text-decoration: none;
}

.login .content-login .content-login-wrapper.user-content.active {
  transform: translateY(0);
}

.login .content-login .content-login-wrapper.team-content.active {
  transform: translateY(0);
  padding-bottom: 30px;
}

.login .content-login .content-login-wrapper.forgot-content.active {
  transform: translateY(0);
}

.login .content-login .content-login-wrapper.forgot-content.active form {
  width: 100%;
}

.login .content-login .content-login-wrapper.forgot-content.active form input {
  width: 100%;
  transition: .3s linear all;
}

.sentEmail {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.sentEmail h1 {
  position: absolute;
  transform: translateY(-50%);
  top: 50%;
  text-align: left;
  width: 100%;
  font-size: 30px;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.sentEmail h1:first-child {
  top: 35%;
}

.login .content-login .content-login-wrapper .login-logo {
  position: relative;
  float: left;
  width: 100%;
  height: 70px;
  margin: 20px 0px 10px 0px;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
}

.login .content-login .content-login-wrapper .login-logo img {
  float: left;
  height: 70px;
}

.login .content-login .content-login-wrapper .login-logo img.register {
  left: 35%;
}

.login .content-login .content-login-wrapper .login-logo img.swiss-logo {
  min-height: 50px;
  width: 60px;
  max-width: 60px;
}

.login .content-login .content-login-wrapper .login-logo h1 {
  padding-left: 4px;
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.5em;
  font-size: 13px;
  /* position: absolute; */
  float: right;
  width: 70%;
  left: 80%;
  /* padding-left: 10px; */
  /* transform: translateX(-67%); */
  /* margin-top: 15px; */
}

.login .content-login .content-login-wrapper .login-logo h1 span {
  font-weight: bold;
}

.login .content-login .content-login-wrapper .verlinkung-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  justify-self: center;
  margin-top: 5rem;
  font-weight: 600;
  width: 60%;
}

.login .content-login .content-login-wrapper .verlinkung-container .bwki-text {
  font-weight: 600;
  margin-top: 0.5rem;
  justify-self: space-around;
}

.login .content-login .content-login-wrapper .verlinkung-wrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  margin-left: 1rem;
  margin-right: 1rem;
  font-weight: 600;
}

.login .content-login .content-login-wrapper .verlinkung-wrapper a {
  /* display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-left: 1rem;
    margin-right: 1rem; */
  font-weight: 600;
}


.login .content-login .content-login-wrapper .login-logo h1.register {
  left: 90%;
  /* transform: translateX(-73%); */
}

.login .content-login .content-login-wrapper .input-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
}

.login .content-login .content-login-wrapper form {
  position: relative;
  float: left;
}


.svg-inline--fa {
  margin: 0 0.5rem;
  padding: 0;
  cursor: pointer;
}

.input-wrapper .svg-inline--fa {
  position: relative;
  float: left;
  background-color: #028ccf;
  color: #fff;
  width: 24px;
  height: 24px;
  /* font-size: 20px; */
  text-align: center;
  line-height: 40px;
  margin: 0;
  margin-right: 2px;
  padding: 8px;
}

.main-header-row {
  background-color: rgba(0, 133, 214, 0.8);
}

.overview-header {
  font-size: 1.15rem;
}

.second-header-row {
  /* background-color: rgba(2, 140, 207, 0.4); */
  background-color: rgba(12, 186, 190, 0.8);
  font-size: 1.05rem;
  /* opacity: .5; */
}

.second-header-row thead th {
  padding: 0.25rem;
  vertical-align: middle;

}

.second-header-row th.overview-header {
  padding: 0.5rem 0.25rem 0.5rem 0.25rem;
  vertical-align: middle;
  font-size: 1rem;
  text-wrap: balance;
  /* rotate: 45deg; */
}

.third-header-row {
  /* background-color: rgba(12, 186, 190, 0.4); */
  background-color: rgba(21, 228, 174, 0.8);
  font-size: 1.05rem;
  /* opacity: .5; */
}

.third-header-row th.overview-sub-header {
  padding: 0.3rem;
  vertical-align: middle;
  /* opacity: .5; */
}


.second-header {
  background-color: rgba(2, 140, 207, .4);

  /* opacity: .5; */
}


.overview-sub-header {
  font-size: 1rem;
}

.overview-sub-header .svg-inline--fa {
  margin: .3rem;
  background-color: #028ccf;
  color: #fff;
  width: 12px;
  height: 12px;
  position: relative;
  float: left;
}

.overview-sub-header .isRotated {
  transform: rotate(90deg);
}

.copy-wrapper .svg-inline--fa {
  position: relative;
  float: left;
  background-color: #028ccf;
  color: #fff;
  width: 24px;
  height: 24px;
  /* font-size: 20px; */
  text-align: center;
  line-height: 40px;
  /* margin: 0; */
  /* margin-right: 2px; */
  padding: 8px;
  margin-top: 2rem;
  border-radius: 10%;
}

.copy-wrapper-small {
  display: inline-flex;
  flex-wrap: nowrap;
  align-self: flex-start;
  align-content: space-between;
  padding: 1px;
  /* align-items: center; */
  margin-left: .5rem;
  border: 1px solid #028ccf;
  border-radius: 5%;
}

.copy-wrapper-small span {
  font-size: .925rem;

}

.copy-wrapper-small .svg-inline--fa {
  position: relative;
  float: right;
  background-color: #028ccf;
  color: #fff;
  width: 14px;
  height: 14px;
  /* font-size: 20px; */
  text-align: center;
  /* line-height: 40px; */
  margin: 2px;
  margin-left: .25rem;
  padding: 2px;
  /* margin-top: 2rem; */
  border-radius: 10%;
}

.hover-text>.tooltip-inner {
  font-size: .925rem;
  opacity: .4;
  margin: 1px;
  padding: 1px;
}

.hover-text>.tooltip-arrow {
  opacity: .3;
  margin: 1px;
  padding: 1px;
}

.login .content-login .content-login-wrapper .input-wrapper i {
  position: relative;
  float: left;
  background-color: #028ccf;
  color: #fff;
  width: 40px;
  height: 40px;
  font-size: 25px;
  text-align: center;
  line-height: 40px;
  margin-right: 2px;
}

.login .content-login .content-login-wrapper .input-wrapper input {
  position: relative;
  float: left;
  width: calc(100% - 42px);
  height: 40px;
  border: none;
  background-color: #028ccf;
  color: #fff;
  padding-left: 5px;
  transition: .5s linear all;
}

.login .content-login .content-login-wrapper .input-wrapper input::placeholder {
  color: #fff;
}

.login .content-login .content-login-wrapper>a {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: right;
  color: #028ccf;
  font-weight: 600;
}

.login .content-login .content-login-wrapper a:hover {
  text-decoration: underline;
}

.login .content-login .content-login-wrapper .button-login {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.login .content-login .content-login-wrapper .button-login button {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 2em;
  background-color: #028ccf;
  color: #fff;
  font-size: 23px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.login .content-login .content-login-wrapper form.register-form-wrapper input[disabled] {
  opacity: .4;
}

.login .content-login .content-login-wrapper form.register-form-wrapper input {
  position: relative;
  float: left;
  width: calc(50% - 20px);
  height: 40px;
  border: none;
  background-color: #028ccf;
  color: #fff;
  padding-left: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .addTeamMember {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .addTeamMembers {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  border: 2px solid #c6baba;
  padding: 10px;
  padding-bottom: 0px;
  margin-bottom: 10px;
  border-radius: 15px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper input::placeholder {
  color: #fff;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .left-part {
  position: relative;
  float: left;
  width: calc(50% - 20px);
  height: auto;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .right-part {
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .left-part input {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  border: none;
  background-color: #028ccf;
  color: #fff;
  padding-left: 5px;
  margin-left: 10px;
  margin-bottom: 10px;
}

.login .content-login .content-login-wrapper form.register-form-wrapper .right-part input {
  position: relative;
  float: left;
  width: 100%;
  height: 40px;
  border: none;
  background-color: #028ccf;
  color: #fff;
  padding-left: 5px;
  margin-bottom: 10px;
  margin-left: 20px;
}

.captcha {
  position: relative;
  float: left;
  width: calc(50% - 20px);
  height: auto;
  margin-left: 10px;
  margin-bottom: 10px;
  top: 100%;
  transform: translateY(-110%);
}

.right-part .checkbox-wrapper {
  position: relative;
  float: left;
  width: calc(50% - 20px);
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  top: 35%
}

.right-part .checkbox-wrapper .checkbox-terms {
  width: 100%
}

.right-part .checkbox-wrapper span.switchLabel {
  margin-right: 0;
  top: -20px;
  width: calc(100% - 90px);
  font-size: 13px;
  line-height: 20px;
}

.right-part .checkbox-wrapper span.switchLabel a {
  width: auto;
  float: none;
}

.login .content-login .content-login-wrapper .button-login.register {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 30px;
}

.login .content-login .content-login-wrapper .button-login.register button {
  position: relative;
  float: left;
  width: 50%;
  height: 40px;
  border: none;
  border-radius: 2em;
  background-color: #028ccf;
  color: #fff;
  font-size: 23px;
  left: 50%;
  transform: translateX(-50%);
}

.login .content-login .content-login-wrapper form.register-form-wrapper .chechboxTeam {
  position: relative;
  float: left;
  width: 50%;
  height: auto;
  padding-left: 10px;
  padding-right: 10px;
  transform: translateX(50%);
}

.login .content-login .content-login-wrapper form.register-form-wrapper .chechboxTeamEmpty {
  /* position: relative;
    float: left; */
  width: 50%;
  height: 50px;
}

.tooltip-span {
  position: relative;
  float: right;
  color: #028ccf;
}

.tooltip.show {
  opacity: 1;
}

.tooltip-inner {
  background-color: #028ccf;
  max-width: 350px;
  height: auto;
  text-align: left;
  border: 3px solid #fff;
  border-radius: 0px;
}

.bs-tooltip-bottom .arrow::before,
.bs-tooltip-auto[x-placement^="bottom"] .arrow::before {
  border-bottom-color: #028ccf;
}

.bs-tooltip-right .arrow::before,
.bs-tooltip-auto[x-placement^="right"] .arrow::before {
  border-right-color: #028ccf;
  display: none;
}

/* CHECKBOX */
/* CHECKBOX */
/* CHECKBOX */
/* CHECKBOX */
.switch {
  position: relative;
  display: inline-block;
  float: left;
  width: 60px;
  height: 34px;
  margin-right: 30px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #fff;
  transition: .4s;
  border: 2px solid #028ccf;
}

.slider:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 30px;
  left: 1px;
  bottom: 0px;
  background-color: #028ccf;
  border: 1.5px solid #fff;
  transition: .4s;
}

.switch input:checked+.slider {
  background-color: #028ccf;
}

.switch input:focus+.slider {
  box-shadow: 0 0 1px #028ccf;
}

.switch input:checked+.slider:before {
  transform: translateX(24px);
}

/* Rounded sliders */
.slider.round {
  border-radius: 34px;
}

.slider.round[disabled] {
  cursor: default !important;
}

.slider.round:before {
  border-radius: 50%;
}

/* CHECKBOX */
/* CHECKBOX */
/* CHECKBOX */
/* CHECKBOX */
/* END OF LOGIN AND REGISTER*/
.header {
  position: relative;
  float: left;
  width: 100%;
  height: 70px;
  background-color: #fff;
  z-index: 999;
  transform: rotate(-0.5deg);
}

.nav-helper {
  position: absolute;
  float: left;
  width: 100%;
  height: 10px;
  background-color: #028ccf;
  transform: rotate(-0.7deg);
  top: 65px;
  z-index: 99;
}

.header .container-fluid {
  height: 100%;
}

.header .header-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.header .header-wrapper .header-nav-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.header .header-wrapper .header-wrapper-center {
  position: relative;
  float: left;
  /*width: calc(100% - 60%);*/
  height: 100%;
}

.header .header-wrapper .header-wrapper-right a,
.header .header-wrapper .header-wrapper-center a {
  position: relative;
  float: left;
  width: 150px;
  height: 100%;
  text-align: center;
  padding-top: 37px;
  color: #111;
  font-size: 16px;
  font-weight: 600;
  -webkit-text-decoration-line: none;
          text-decoration-line: none;
}

.header .header-wrapper .header-wrapper-right a {
  width: 30%;
}

.header .header-wrapper .header-wrapper-right {
  position: relative;
  float: left;
  width: 30%;
  height: 100%;
}




.content .container-fluid {
  height: 80%;
}

.task-main {
  margin-top: 3em;
  height: auto;
  width: 80%;
  max-width: 900px;
  justify-self: center;
  display: inline-block;
}

.task-content {
  border: 1px solid #0cbabe;
}

.previous-link {

  font-weight: 600;
  color: #000;
  text-decoration: underline;
  cursor: pointer;
  font-size: 1.25rem;
}

.task-main p {
  padding: 1em 1em 0em 1em;
  margin: 0px;
}

.task-main .task-header {
  min-height: 3em;
  height: auto;
  width: 100%;
  /* background-color: #0083d3; */
  padding: 1em;
  display: inline-block;

}

.task-header .tasks-schueler {
  float: left;
  height: 2.5em;
}

.task-header .task-title {
  float: left;
  font-weight: bold;
  color: #FFF;
  font-size: 1.2em;
}

.task-header .task-category {
  border: 1px solid #212529;
  border-radius: 5px;
  padding: 2px 15px;
  background-color: #FFF;
  text-align: center;
  float: right;
  min-width: 100px;
  width: auto;
  margin: 0px;
}

.task-footer {
  padding: 1em;
  width: 100%;
  display: inline-block;
}

.task-footer>a {
  border: 1px solid #0cbabe;
  border-radius: 3px;
  float: left;
  color: #111;
  padding: 0.5em;
  margin: 0.5em;
}

.task-footer>a:hover {
  border: 1px solid #0cbabe;
  padding: 0.5em;
  margin: 0.5em;
  text-decoration: none;
}

.task-diff-img {
  height: 60px;
  width: 60px;
  float: right;
  object-fit: cover;
  object-position: 5px 6px;
}



.myAlert {
  position: absolute;
  z-index: 99999;
  height: 80px;
  max-height: 80px;
  width: 100%;
  background-color: #f5d986;
  float: left;
  margin-top: 64px;
  padding: 10px;
  transform: translateY(-250px);
  transition: .5s linear all;
  z-index: 1;
  opacity: 0;
  overflow-y: scroll;
  padding-top: 16px;
}

.myAlert.auth {
  margin-top: 0;
}

.myAlert.show {
  opacity: 1;
  transform: translateX(0px);
}

.myAlert span {
  position: relative;
  float: left;
  width: 95%;
  height: auto;
  text-align: center;
  color: #111;
  font-size: 18px;
  line-height: 35px;
}

.myAlert i {
  position: relative;
  float: right;
  width: 20px;
  height: 20px;
  transform: translateY(-50%);
  cursor: pointer;
  top: 50%;
}

.content .middle-content {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding: 0px 15px;
  padding-bottom: 30px;
  /* perspective: 1200px; */
  top: 10px;
}

.content .home-wrapper .text-above {
  text-align: center;
  width: 50%;
  left: 50%;
  position: relative;
  transform: translateX(-50%);
  z-index: 99999;
}

.content .middle-content a.content-box:hover {
  cursor: pointer;
}

.content .middle-content a.content-box {
  position: relative;
  float: left;
  width: calc(calc(100% / 3) - 10px);
  margin-right: 10px;
  height: 70%;
  background-color: #0083d3;
  top: 50%;
  padding: 20px;
  transform-style: preserve-3d;
  transform: translateY(-50%);
  cursor: auto;
  /* overflow-y: scroll;
    overflow-x: hidden; */
}

/* .content .middle-content a.content-box:first-child {
    transform: translateY(-50%) rotateY(40deg);
    margin-left: 55px;
}

.content .middle-content a.content-box:nth-child(2) {
    height: 62%;
}

.content .middle-content a.content-box:last-child {
    transform: translateY(-50%) rotateY(-40deg);
} */

.content .middle-content a.content-box .inside-box {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  padding-right: 20px;
}

.content .middle-content a.content-box .inside-box .inside-box-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  top: 50%;
  transform: translateY(-50%);
}

.content .middle-content a.content-box .inside-box .inside-box-wrapper h1 {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 32px;
  margin-bottom: 30px;
}

.content .middle-content a.content-box .inside-box .inside-box-wrapper span {
  position: relative;
  float: left;
  width: 100%;
  text-align: left;
  color: #fff;
  padding-bottom: 15px;
  font-weight: 500;
}

.footer {
  position: relative;
  float: left;
  width: 100%;
  /* height: calc(30vh - 4px); */
  height: 25vh;
  background-color: #0083d3;
}

.footer.entry-footer {
  height: 3rem;
  background-color: transparent;
  font-weight: bold;
}

.footer .footer-content {
  position: absolute;
  float: left;
  width: 100%;
  bottom: 10px;
}



.code-page-limit {
  position: relative;
  max-width: 950px;
  width: 100%;
  float: left;
  transform: translateX(-50%);
  left: 50%
}

.footer .footer-content .footer-conten-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.footer .footer-content .footer-conten-wrapper a {
  position: relative;
  float: left;
  font-size: 1rem;
  width: 10rem;
  text-align: center;
  color: #fff;
}

.footer .footer-content .footer-conten-wrapper a:hover {
  text-decoration: none;
  font-weight: bold;
}

.footer .copyright {
  position: absolute;
  left: 0;
  color: white;
  font-size: 1rem;
  margin-left: 2rem;
}

@media only screen and (max-width: 1200px) {


  .footer.entry-footer {
    height: 5rem;

  }

  .footer .copyright {
    position: absolute;
    left: 0;
    /* width: 100%; */
  }
}

.footer .footer-content .footer-conten-wrapper a:first-child {
  text-align: right;
}

.footer .footer-content .footer-conten-wrapper a:last-child {
  text-align: left;
}

.womix__header-graphic {
  position: absolute;
  overflow-x: hidden;
  width: 100%;
  height: 100%;
  background-color: white;
}

.womix__header-graphic svg {
  filter: drop-shadow(rgba(0, 0, 0, .2) 0 0 8px);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 110%;
}

.womix__header-graphic svg.header_graphic-layer-1 {
  left: -15%;
  transform: translate(-1.212005%, 0%) translate3d(0px, 0px, 0px);
}

.womix__header-graphic svg.header_graphic-layer-2 {
  left: -10%;
  transform: translate(12.093013%, 0%) translate3d(0px, 0px, 0px);
}

.womix__header-graphic svg.header_graphic-layer-3 {
  left: -5%;
  transform: translate(-1.637303%, 0%) translate3d(0px, 0px, 0px);
}

.womix__header-graphic svg.header_graphic-layer-1 polygon {
  fill: #15e4ae;
}

.womix__header-graphic svg.header_graphic-layer-2 polygon {
  fill: #0cbabe;
}

.womix__header-graphic svg.header_graphic-layer-3 polygon {
  fill: #0083d3;
}







.question-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}


.task-tittle {
  position: relative;
  width: 85%;
  height: auto;
  box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
  padding: 10px 30px;
  margin-bottom: 20px;
  left: 10%;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
}


.submitted-questions-label.error .tooltip-span,
.submitted-questions-label.error {
  color: #ff0000;
}

.nsewdrag.drag {
  width: 200px;
  height: 200px;
}

input.invalid {
  border: 1px solid red !important;
}

.login button[disabled],
.submit-idea button[disabled] {
  opacity: .7 !important;
}

.login button[disabled] {
  cursor: default !important;
}

.login button {
  cursor: pointer;
}

.bottom-part {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.bottom-part .row {
  margin: 0;
}

.code-submission-content {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  margin-bottom: 20px;
}



.code-response {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  max-height: 350px;
  border: 3px solid #0083d3;
  min-height: 200px;
}

.ace-editor-wrapper .ace-monokai {
  border: 3px solid #0083d3;
  height: 600px !important;
}

.removePadding {
  padding: 0;
}

.code-response-wrapper.success {
  position: relative;
  float: left;
  width: 100%;
  background-color: #7bed9f;
  height: auto;
}

.code-response-wrapper.success p {
  color: #009432;
  font-size: 18px;
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 2px !important;
  margin-right: 10px;
}

.code-response-wrapper.error {
  position: relative;
  float: left;
  width: 100%;
  background-color: #ff7675;
  height: auto;
  border-bottom: 1px solid #d63031;
}

.code-response-wrapper.error p {
  color: #d63031;
  font-size: 18px;
  padding: 5px;
  padding-left: 10px;
  margin-bottom: 2px !important;
  margin-right: 10px;
}

.code-response-wrapper.success i,
.code-response-wrapper.error i {
  padding-right: 10px;
}

.code-response-wrapper .svg-inline--fa {
  cursor: auto;
}

.code-response-wrapper.error p .color-triangle {
  color: #ffd32a;
}

.code-response-wrapper.success p .color-square {
  color: #009432;
}

.code-response .response-first-part {
  position: relative;
  float: left;
  width: 100%;
  height: 80%;
  overflow-x: hidden;
  overflow-y: scroll;
}

.code-response .response-second-part {
  position: relative;
  float: left;
  width: 100%;
}

.code-response .response-second-part button {
  position: relative;
  float: right;
  margin: 25px;
}

.content.submission .code-response-button:hover {
  cursor: pointer;
}

.content.submission .code-response-button {
  position: absolute;
  float: left;
  width: 95px;
  height: 52px;
  top: 100%;
  transform: translate(-100%, -100%);
  left: 100%;
  background-color: #0083d3;
  border: none;
  cursor: pointer;
  z-index: 9999;
}

.content.submission .code-reset-button:hover {
  cursor: pointer;
}

.content.submission .code-reset-button {
  position: absolute;
  float: left;
  width: 95px;
  height: 52px;
  top: 100%;
  transform: translate(0%, -100%);
  background-color: #0083d3;
  border: none;
  cursor: pointer;
  z-index: 9999;
  margin-right: 1%;
}


.status-middle-content {
  position: relative;
  float: left;
  width: 100%;
  min-height: 500px;
  height: 500px;
}

.status-box {
  position: relative;
  float: left;
  width: calc(calc(100% / 3) - 10px);
  height: 300px;
  margin-right: 10px;
  top: 50%;
  transform: translateY(-50%);
  box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
  cursor: auto !important;
}

.status-box:nth-child(2) {
  height: 370px;
  margin-top: 15px;
}

.status-box .status-title {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  background-color: #028ccf;
}

.status-box .status-title h1 {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  color: #fff;
  font-size: 25px;
  line-height: 45px;
}

.status-box .status-date {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  padding: 0px 20px;
}

.status-box .status-date span {
  position: relative;
  float: left;
  width: 60%;
  color: #111;
  font-size: 15px;
  line-height: 65px;
  height: 100%;
  font-size: 16px;
  font-weight: 600;
}

.status-box .status-date p {
  position: relative;
  float: left;
  width: 40%;
  height: 100%;
  line-height: 65px;
  color: #787878;
  font-size: 15px;
}

.status-box .status-content {
  position: relative;
  float: left;
  width: 100%;
  padding: 0px 20px;
  height: 130px;
}

.status-box .status-content span {
  position: relative;
  float: left;
  width: 100%;
  top: 30%;
  transform: translateY(-70%);
  font-size: 16px;
  font-weight: 600;
}

.status-box .status-content span:first-child {
  margin-bottom: 10px;
}

.status-box .status-content span.success {
  color: green;
}

.status-box .status-content span.partly {
  color: orange;
}

.status-box .status-content span.error {
  color: red;
}

.status-box .status-button {
  position: absolute;
  float: left;
  width: 100%;
  height: 70px;
  bottom: 0;
}

.status-box .status-points {
  position: relative;
  float: left;
  width: 100%;
  height: 138px;
  padding: 30px 20px 0px 20px;
}

.status-box .status-points span {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  text-align: center;
}

.status-box .status-points span:first-child {
  color: #787878;
  font-size: 19px;
  font-weight: 600;
  margin-bottom: 10px;
}

.status-box .status-points span:last-child {
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
  font-size: 35px;
  font-weight: 700;
}

.status-button-idee a:hover,
.status-box .status-button a:hover,
.completedBtn:hover {
  text-decoration: none !important;
}

.status-box .status-button a {
  position: absolute;
  float: left;
  width: 200px;
  height: 35px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #0083d3;
  color: #fff;
  border: none;
  border-radius: 2em;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  line-height: 35px;
}

.status-button-idee {
  position: relative;
  float: left;
  width: 100%;
}

.status-button-idee a {
  position: absolute;
  float: left;
  width: 270px;
  height: 35px;
  left: 50%;
  /* transform: translateX(-50%); */
  background-color: #0083d3;
  color: #fff;
  border: none;
  border-radius: 2em;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  top: 0%;
  transform: translate(-50%, -80%);
  text-align: center;
  line-height: 35px;
}

.bestenliste {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.submit-idea .title,
.bestenliste .title {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
}

.submit-idea .title {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
}

.submit-idea .title span,
.bestenliste .title span {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.bestenliste .table-list {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 50px;
}

.bestenliste .table-list .liste-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 60px;
  margin-bottom: 15px;
  /* box-shadow: 5px 5px 20px -8px rgba(0, 0, 0, 1); */
  border: 1px solid #999;
}

.bestenliste .table-list .liste-wrapper .number-list {
  position: relative;
  float: left;
  width: 6%;
  height: 100%;
  background-color: #0083d3;
}

.bestenliste .table-list .liste-wrapper .number-list span {
  position: absolute;
  float: left;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 32px;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */

.bestenliste .table-list .liste-wrapper .title-team {
  position: relative;
  float: left;
  width: 65%;
  height: 100%;
  overflow: hidden;
}

.bestenliste .table-list .liste-wrapper .title-team span {
  position: absolute;
  float: left;
  font-size: 15px;
  line-height: 58px;
  padding-left: 10px;
}

.bestenliste .table-list .liste-wrapper .avatar-team {
  position: relative;
  float: left;
  width: 10%;
  height: 100%;
}

.bestenliste .table-list .liste-wrapper .avatar-team span {
  position: relative;
  float: right;
  width: 40px;
  height: auto;
  padding: 5px;
  border: 2px solid #f1eeee;
  border-radius: 50%;
  top: 50%;
  transform: translateY(-50%);
}

.bestenliste .table-list .liste-wrapper .avatar-team span canvas {
  position: relative;
  float: left;
  width: 100% !important;
  height: 100% !important;
}

.bestenliste .table-list .liste-wrapper .name-team {
  position: relative;
  float: left;
  width: 25%;
  height: 100%;
}

.bestenliste .table-list .liste-wrapper .name-team span {
  position: relative;
  float: left;
  text-align: left;
  padding-left: 10px;
  line-height: 58px;
  font-size: 15px;
  font-weight: bold;
  color: #615f5f;
}

.pagination-team {
  position: relative;
  float: left;
  width: 100%;
}

.pagination-team .pagination {
  position: relative;
  float: left;
  width: 100%;
  justify-content: center;
}

.button-team {
  left: 50%;
  transform: translateX(-50%);
}

.comment-button {
  position: relative;
  float: left;
  left: 50%;
  transform: translateX(-50%)
}

.button-zuruck {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 100px;
}

.button-zuruck a {
  position: relative;
  float: left;
  left: 50%;
  transform: translateX(-50%)
}

.textAreaTeam {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-top: 50px;
  margin-bottom: 10px;
  border: 2px solid #0083d3;
}

.blueColor {
  color: #0083d3;
}

.textAreaTeam textarea {
  position: relative;
  float: left;
  width: 100%;
  height: 150px;
  resize: none;
}

.viewTeam {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  border: 2px solid #0083d3;
  margin-top: 15px;
}

.viewTeam p {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding: 30px;
  margin-bottom: 40px;
}

.viewTeam .viewTeam-user {
  position: absolute;
  float: left;
  width: 100%;
  height: auto;
  bottom: 0;
  background-color: lightgray;
  padding: 7px 0px;
}

.viewTeam .viewTeam-user .viewTeam-userImg canvas {
  position: relative;
  float: left;
  width: 30px !important;
  height: 30px !important;
  margin-right: 20px;
  border: 1px solid #f1eeee;
  padding: 4px;
  border-radius: 50%;
}

.viewTeam .viewTeam-user span:first-child {
  position: relative;
  float: left;
  text-align: right;
  width: 60%;
  padding-right: 20px;
}

.viewTeam .viewTeam-user span:nth-child(3) {
  position: relative;
  float: left;
  width: auto;
  margin-right: 20px;
  font-weight: bold;
  color: #615f5f;
}

.viewTeam .viewTeam-user span:last-child {
  position: relative;
  float: right;
  margin-right: 20px;
}

.viewTeam-userImg {
  position: relative;
  float: left;
  width: 5%;
}

.user-date {
  position: relative;
  float: left;
  width: 35%;
  border-bottom: 2px solid lightgray;
}

.user-date span:first-child {
  text-align: left !important;
  font-weight: bold;
}

.cursor-pointer {
  cursor: pointer;
}

/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
/*  */
.bestenliste .table-list .liste-wrapper .name-list {
  position: relative;
  float: left;
  width: 40%;
  height: 100%;
  padding-left: 30px;
}

.bestenliste .table-list .liste-wrapper .name-list span {
  position: relative;
  float: left;
  text-align: left;
  line-height: 58px;
  font-size: 22px;
}

.bestenliste .table-list .liste-wrapper .points-list {
  position: relative;
  float: left;
  width: 20%;
  height: 100%;
}

.bestenliste .table-list .liste-wrapper .points-list span {
  position: relative;
  float: left;
  text-align: left;
  line-height: 58px;
  font-size: 22px;
}

.bestenliste .table-list .liste-wrapper .avatar-list {
  position: relative;
  float: left;
  width: 34%;
  height: 100%;
  padding-right: 60px;
  border-right: 1px solid #bebebe;
}

.bestenliste .table-list .liste-wrapper .avatar-list span {
  position: relative;
  float: right;
  width: 60px;
  height: 100%;
  padding: 5px;
}

.bestenliste .table-list .liste-wrapper .avatar-list span canvas {
  position: relative;
  float: left;
  width: 100% !important;
  height: 100% !important;
}

.table-list .border-wrapper {
  border-top: 1px solid #bebebe;
  border-bottom: 1px solid #bebebe;
}

.hideLeftMenuBtn {
  display: none;
}

.submit-idea .beschreibung,
.submit-idea .herkunft,
.submit-idea .datei,
.submit-idea .thema {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  margin-bottom: 25px;
}

.submit-idea .herkunft textarea {
  position: relative;
  float: left;
  width: 100%;
  height: 100px;
  border: 2px solid #0083d3;
  resize: none;
}

.submit-idea .beschreibung textarea {
  position: relative;
  float: left;
  width: 100%;
  height: 200px;
  border: 2px solid #0083d3;
  resize: none;
}

.submit-idea input {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
  border: 2px solid #0083d3;
}

.submit-idea .datei input {
  position: relative;
  float: left;
  width: 70%;
  height: 30px;
  border: 2px solid #0083d3;
}

.submit-idea .datei button.myButton {
  position: relative;
  float: left;
  width: 30%;
  height: 30px;
  background-color: #0083d3;
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
}

.submit-idea form span.labelDesc {
  color: #0083d3;
  font-size: 15px;
  font-weight: 600;
  position: relative;
  float: left;
  width: 100%;
}

.submit-idea form button.myButton {
  position: relative;
  float: left;
  width: 200px;
  height: 35px;
  background-color: #0083d3;
  color: #fff;
  border: none;
  border-radius: 2em;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  margin-top: 30px;
}

.submitedIdea {
  position: relative;
  float: left;
  width: 100%;
  height: 300px;
}

.submitedIdea .submited-wrapper {
  position: absolute;
  width: 100%;
  text-align: center;
  bottom: 0;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.multiple-choise-video .title {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
  margin-bottom: 30px;
}

.multiple-choise-video .title span {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.completedBtn {
  position: relative;
  float: left;
  width: 250px;
  height: 40px;
  background-color: #0083d3;
  color: #fff;
  border: none;
  border-radius: 2em;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: 600;
  text-align: center;
  line-height: 35px;
}

.switchLabel {
  float: left;
  position: relative;
  margin-right: 30px;
  line-height: 34px;
}

.left-menu {
  position: absolute;
  float: left;
  width: 40%;
  height: 100vh;
  margin-top: 70px;
  background-color: #fff;
  z-index: 9;
  padding: 50px;
  transform: translateX(-1500px);
  /* transition: .4s linear all; */
}

.left-menu .title {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
}

.left-menu .title span {
  position: relative;
  float: left;
  width: auto;
  height: 100%;
  font-size: 16px;
  font-weight: bold;
}

.left-menu .title img {
  position: relative;
  float: right;
  width: 30px;
  height: 30px;
  cursor: pointer;
}

.left-menu .questions {
  position: relative;
  float: left;
  width: 100%;
  /* min-height: 500px; */
  height: auto;
  max-height: 83%;
  overflow: auto;
  padding-right: 10px;
}

.left-menu .questions .question-inside {
  position: relative;
  float: left;
  width: 100%;
  height: 60px;
  background-color: #fff;
  margin-bottom: 10px;
  box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1);
}

.left-menu .questions .question-inside:hover {
  cursor: pointer;
}

.left-menu .questions .question-inside.active {
  margin-left: 3px;
  border: 2px solid #0083d3;
  width: calc(100% - 3px);
}

.left-menu .questions .question-inside.hide {
  opacity: .4;
}

.left-menu .questions .question-inside .number-question {
  position: relative;
  float: left;
  width: 10%;
  height: 100%;
  background-color: #0083d3;
}

.left-menu .questions .question-inside .number-question span {
  position: absolute;
  float: left;
  font-size: 30px;
  color: #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.left-menu .questions .question-inside.video .number-question {
  width: 20%;
}

.left-menu .questions .question-inside.video .number-question span {
  height: 100%;
  width: 100%;
}

.left-menu .questions .question-inside.video .number-question span img {
  width: 100%;
  height: 100%;
}

.left-menu .questions .question-inside.video .content-question {
  width: 80%;
}

.left-menu .questions .question-inside .content-question {
  position: relative;
  float: left;
  width: 90%;
  height: 100%;
  padding: 5px 10px;
  overflow: hidden;
}

.left-menu .questions .question-inside .content-question span {
  position: relative;
  float: left;
  width: 100%;
  color: #111;
}

.left-menu.show {
  /* transform: translateX(0); */
  animation: showLeftMenu 1s forwards;
}

.left-menu.hide {
  /* transform: translateX(0); */
  animation: hideLeftMenu 1s forwards;
}

.overflowHidden {
  overflow: hidden;
}

@keyframes showLeftMenu {
  0% {
    transform: translateX(-1500px);
    height: 1500px;
  }

  30% {
    transform: translateX(-382px) rotate(-24deg);
    height: 1500px;

  }

  97% {
    transform: translateX(0);
    /* height: 100vh !important; */
    box-shadow: none;
  }

  100% {
    transform: translateX(0);
    /* height: 100vh !important; */
    box-shadow: 0px 0px 60px -10px rgba(0, 0, 0, 1);
  }
}

@keyframes hideLeftMenu {
  0% {
    transform: translateX(0);
    height: 1500px;
    box-shadow: none;
  }

  30% {
    transform: translateX(-382px) rotate(-24deg);
    height: 1500px;
    box-shadow: none;
  }

  100% {
    transform: translateX(-1500px);
    height: 0px;
    box-shadow: none;
  }

}

.fade-appear,
.fade-enter {
  opacity: 0;
  z-index: 1;
}

.fade-appear-active,
.fade-enter.fade-enter-active {
  opacity: 1;
  transition: opacity 300ms linear 150ms;
}

.fade-exit {
  opacity: 0;
}

.fade-exit.fade-exit-active {
  opacity: 0;
  transition: opacity 150ms linear;
}

.not-found {
  position: relative;
  float: left;
  width: 100%;
  min-height: calc(calc(100vh - 85px) - 25vh)
}

.not-found h1 {
  position: absolute;
  float: left;
  width: auto;
  height: auto;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #333;
}

.checkbox-wrapper {
  position: relative;
  float: left;
  width: 100%;
}

.DayPickerInput {
  width: calc(50% - 20px);
}

.DayPickerInput input {
  width: 100% !important;
}

.DayPickerInput-Overlay {
  top: 40px;
}

@media only screen and (max-width: 1024px) {
  .nav-logo {
    width: 25%;
  }

  .header .header-wrapper .header-wrapper-center {
    width: 50%;
  }

  .header .header-wrapper .header-wrapper-right {
    width: 25%;
  }

  .header .header-wrapper .header-wrapper-center a {
    width: 120px;
  }
}

@media only screen and (min-width: 1900px) {
  .container-fluid.home-wrapper {
    padding: 0px 25px !important;
  }
}

.content-login.overflow {
  overflow: unset;
}

@media only screen and (max-height: 735px) {
  .status-middle-content {
    height: 50vh;
    min-height: 50vh;
  }
}

.bestenliste-message {
  position: relative;
  float: left;
  width: 100%;
  text-align: center;
  margin-top: 20px;
}

.nav-logo a {
  color: black;
}

.long-text {
  line-height: 30px !important;
  width: 100% !important;
  margin-top: 20px;
}

.submit-idea-video {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 560px;
}

.olderWarning {
  color: #856404;
  background-color: #fff3cd;
  border-color: #ffeeba;
  position: relative;
  float: left;
  padding: 10px;
  margin: 10px;
}

.LinesEllipsis {
  position: absolute;
  float: left;
  font-size: 15px;
  line-height: 58px;
  padding-left: 10px;
}

.std-output {
  color: black !important;
  position: relative;
  float: left;
  width: 100%;
  background-color: white;
  height: auto;
  white-space: pre-line
}

.cookiebanner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  left: 0;
  width: 100vw;
  height: 60px;
  z-index: 100;
  background: #fff;
  color: #000;
  padding: 5px 16px;
  font-family: 'Open Sans';
  font-weight: 300;
  font-size: 1.2em;
  text-align: center;
  bottom: 0;
  opacity: 1
}

.cookiebanner-close {
  margin-top: 5px;
  margin-left: 40px;
  cursor: pointer;
  color: #fff;
  background: #0083d3;
  padding: 2px 15px;
  border: 2px solid #0083d3;
  border-radius: 20px
}

/* TIPPS, TRICKS $ LINKS */
.tips-title {
  font-weight: 800 !important;
  font-size: large !important;
  line-height: 2;
}

.tips-description {
  font-weight: 450 !important;
  /* font-size: 20px; */
  font-size: medium !important;
  line-height: 2;
}

.code-page-limit-tipps .links-title {
  font-weight: 600 !important;
  font-size: large !important;
  line-height: 2;
}

#editor * {
  font-family: monospace !important;
  font-size: 16px !important;
  direction: ltr !important;
  text-align: left !important;
}

.red {
  color: red;
}

.certificate {
  font-size: 25px;
  margin-top: 150px;
  font-weight: bold;
}

.code-page-limit-tipps {
  position: relative;
  max-width: 950px;
  width: 100%;
  float: left;
  transform: translateX(-50%);
  left: 50%;

}


.tasks-description {
  overflow: hidden;
  margin-bottom: 30px;
}

.tasks .title span {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  font-size: 35px;
  font-weight: bold;
  text-align: center;
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text;
  color: transparent;
}

.tasks-message {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 20px;
}

.tasks-message ul {
  list-style: disc inside none;
}

.task-selector {
  margin-bottom: 160px;
  overflow: hidden;
}

.task-selector h3 {
  font-size: 22px;
}

.task-difficulty {
  float: left;
  width: 30%;
}

.task-difficulty img {
  height: 50px;
  width: 50px;
  float: left;
  margin-right: 5px;
  cursor: pointer;
  object-fit: cover;
  object-position: 5px 6px;

}

.task-difficulty img.selected {
  border: 1px solid #028ccf;
  border-radius: 5px;
}

.task-difficulty .clear,
.task-category .clear {
  float: left;
  background-color: transparent;
  border-radius: 50%;
  text-align: center;
  height: 25px;
  width: 25px;
  margin: 5px 0px;
  padding: 2px 0px;
  transform: translateY(25%);
  font-size: small;
  border: 1px solid darkgrey;
  cursor: pointer;
}

.task-category {
  float: right;
  width: 70%;
}

.task-category .clear {
  transform: translateY(3px);
}

.task-category p {
  border: 1px solid #212529;
  border-radius: 5px;
  margin: 5px 10px;
  padding: 2px 15px;
  text-align: center;
  float: left;
  cursor: pointer;
}


.task-category p.selected {
  border: 1px solid #028ccf;
}

.tasks-box {
  position: relative;
  float: left;
  width: calc(calc(100% / 3) - 10px);
  height: 270px;
  margin-right: 10px;
  top: 50%;
  transform: translateY(-50%);
  /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
  cursor: pointer !important;
  /* background-color: #028ccf; */
  color: #fff;
  margin-bottom: 30px;
  background-repeat: no-repeat;
  background-position: bottom;
}

.tasks-title h1 {
  font-size: 20px;
  font-weight: bold !important;
  margin: 15px;
}

.tasks-objective {
  margin: 15px;
  font-size: 16px;
  font-style: italic;
  font-weight: 600;

}

.tasks-identifier {
  background-color: #fff;
  height: 70px;
  position: absolute;
  bottom: 0;
  width: 100%;
  border-bottom: 1px solid #BBB;
  border-left: 1px solid #BBB;
  border-right: 1px solid #BBB;
}

.tasks-identifier p {
  color: #212529;
  border: 1px solid #212529;
  border-radius: 10px;
  margin: 15px;
  padding: 5px 10px;
  max-width: 60%;
  text-align: center;
  float: left;
}

.tasks-identifier img {
  height: 100%;
  width: 70px;
  float: right;
  object-fit: cover;
  object-position: 8px 8px;
}

.tasks-schueler {
  height: 50px;
  float: right;
  margin-right: 15px;

}

.success-state {
  color: green;
  margin-left: 5px;
}

.failed-state {
  color: rgba(250, 0, 54, 0.582);
  margin-left: 5px;
}
.primary-color-lightblue-background {
  background-color: #0cbabe;
}

.primary-color-blue-background {
  background-color: #0083d3;
}

.primary-color-lightgreen-background {
  background-color: #00E3A7;
}

.primary-color-lightblue-foreground {
  color: #0cbabe !important;
}

.primary-color-blue-foreground {
  color: #0083d3 !important;
}

.primary-color-blue-foreground-bold {
  color: #0083d3 !important;
  font-weight: bold;
}

.primary-color-lightgreen-foreground {
  color: #00E3A7 !important;
}

.text-center {
  text-align: center;
}

.mt-0 {
  margin-top: 0 !important
}

.mb-0 {
  margin-bottom: 0 !important;
}

/* General Structure */

body {
  position: relative !important;
  overflow-x: hidden !important;
}

.height100vh {
  height: 100vh !important;
}

.padding-top {
  padding-top: 100px !important;
}

.container-fluid {
  padding: 0px !important;
  position: relative !important;
  float: left !important;
  width: 100% !important;
}

.container-limit {
  width: 100%;
  max-width: 960px;
  margin: 0 auto;
}

.container-limit.wide-limit {
  max-width: 1440px;
}



.container-limit.extra-limit {
  max-width: 1440px;
}

.container-limit.my-limit {
  max-width: 900px;
}

.container-no-limit {
  width: 100% !important;
  max-width: 100% !important;
}

.container {
  width: 100%;
  position: relative;
}

.container-color {
  background-color: #f5f3f7 !important;
}

.content-base {
  min-height: calc(100vh - (25vh + 85px));
  width: 100%;
  display: flex;
  justify-content: center;
}

/* General Structure End*/


.app-banner-wrapper {
  position: relative;
  /* float: left; */
  width: calc(100% - 4rem);
  height: 5rem;
  margin: 1rem 2rem;
  z-index: 999;
}

.banner-grid {
  width: 100%;
  display: grid;
  grid-template-columns: 15rem auto 3rem;
}


.banner-logo {
  position: relative;
  float: left;
  width: 10.5rem;
  height: 55px;
  margin: 15px 0 15px 0;
  border-right: 2px solid #999;
}

.banner-logo img {
  position: relative;
  float: left;
  height: 50px;
  width: auto;
  /* width: 200px; */
  /* max-width: 200px; */
  /* height: auto; */
  margin-right: 1rem;
  /* left: 10%;
    transform: translateX(-10%) */
}

.banner-logo h1 {
  font-family: "Open Sans";
  font-weight: 300;
  font-size: 1.2em;
  line-height: 1.5em;
  font-size: 13px;
  position: relative;
  float: left;
  width: 70%;
  margin-top: 12px;
}

.banner-logo h1 span {
  font-weight: bold
}

.banner-logo a {
  color: black;
}


/* @media only screen and (max-width: 1024px) {
  .banner-logo {
    width: 25%;
  }
} */

.margin-top-5 {
  margin-top: 3%;
}

.content {
  position: relative;
  float: left;
  width: 100%;
  min-height: calc(100vh - (27.5vh + 85px));
  margin-top: 16px;
  margin-bottom: 16px;
  /* overflow-y: auto; */
}

/* @media only screen and (max-width: 768px) { */
@media only screen and (orientation: portrait) {
  .content {
    min-height: 99vh;
    margin-bottom: 1rem;
  }

  .font-main-header {
    font-size: 3rem !important;
    margin-top: 2rem;
  }
}

.homepage {
  margin-bottom: 0px;
}



.menu-right {
  grid-column: 3;
  cursor: pointer;
  height: 3rem;
  width: 3rem;
  float: right;
  margin: 1rem 0rem;
}


.main-content {
  position: relative;
  display: block;
  width: 100%;
  min-height: calc(100vh - (25vh + 85px));
  margin-top: 16px;
  margin-bottom: 30px;
  /* overflow-y: auto; */
}

.tiles-container {

  display: flex;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  flex-wrap: wrap;

}

.tutorials-tiles {
  display: flex;
  justify-content: center;
  min-height: 30rem;
  flex-wrap: wrap;
  /* margin-bottom:5rem; */
}

.tutorial-landing {

  width: 100%;
  display: flex;
  justify-content: center;
}

.narrow-tile-container {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  align-self: center;
}


.header-wrapper.entry {
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-banner-dropdown {
  /* height: 27.5rem; */
  overflow: hidden;
  width: 18rem;
  position: absolute;
  right: 2.25rem;
  top: 4.5rem;
  padding: 1rem 1rem 0rem 1rem;
  background: #EEE;
  z-index: 100000;
}

.breadcrumb-dropdown {
  height: 11rem;
  width: 17rem;
  position: absolute;
  top: 5rem;
  left: 20rem;
}

.app-banner-dropdown li {
  margin: 0 0.5rem 0.5rem 0.5rem;
  border-bottom: 0.5px solid #333;
  padding-bottom: 0.25rem;
}

.app-banner-dropdown.breadcrumb-dropdown li:last-child {
  border: 0;
}

.app-banner-dropdown a {
  display: block;
  color: #000;
  font-size: 1.2rem;
  padding: 0.25rem 0rem;
  text-decoration: none;
}


.app-banner-dropdown a:hover {
  font-weight: 600;
  color: #000;
  text-decoration: none;
  /* background-color: #0CBABE; */
}

.app-banner-dropdown a.woher:hover {
  font-weight: 600;
  color: #000;
  text-decoration: none;
  background-color: #0085D6;
}

.app-banner-dropdown a.wie-baut:hover {
  font-weight: 600;
  color: #000;
  text-decoration: none;
  background-color: #0CBABE;
}

.app-banner-dropdown a.wie-geht:hover {
  font-weight: 600;
  color: #000;
  text-decoration: none;
  background-color: #15E4AE;
}

.app-banner-dropdown .logout {
  width: 100%;
  text-align: center;
  font-weight: bold;
}

.breadcrumb-main {
  height: 100%;
  width: 100%;
  /* width: calc(100% - 5rem); */
  padding: 1.2rem 0rem 0rem 0rem;
  margin-left: auto;
  margin-right: auto;
  font-size: 1.25rem;
}

.breadcrumb-main a {
  float: left;
  width: auto;
  margin: 0.5rem 0rem 0.5rem 0.5rem;
  color: #333;
  font-weight: bold;
}

.breadcrumb-main a:hover {
  color: #333;
  text-decoration: none;
}

.breadcrumb-main span {
  margin-right: 0.4rem;
}


.font-main-header {
  font-size: 1.75rem;
  font-weight: bold;
  /* background-image: linear-gradient(to left, blue 50%, red 0%); */
  color: #000;
  text-align: center;
  font-weight: 600;

}

.font-sub-header,
div.homepage.sub-header.font-sub-header,
div.main-content .font-sub-header {
  font-size: 1.25rem;
  font-weight: normal !important;
}

.homepage.header1 {
  width: 90%;
  height: auto;
  margin: 1% 5%;

}

.homepage.header2 {
  max-width: 75rem;
  width: 90%;
  margin: 1rem auto;
}

.task-archive-header {
  width: 60rem !important;
}

.homepage .path-tiles,
.subpath-tiles {
  max-width: 1440px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* float: left; */
  margin: auto;
  /* height: 100%; */
  padding: 0px 15px;
  /* padding-bottom: 30px; */
}

.subpath-tiles {
  max-width: 1440px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* float: left; */
  margin: auto;
  /* height: 100%; */
  padding: 0px 15px;
  /* padding-bottom: 30px; */
}

.homepage .homepage-tile {
  /* width: 25%; */
  width: 23rem;
  height: 23rem;
  float: left;
  margin: 1rem 2rem;
}

.homepage-tile.layout {
  position: relative;
}

.homepage-tile.layout .layout-wrapper {
  position: relative;
  width: 23rem;
  height: 23rem;
}

.homepage-tile.layout .layout-wrapper .tile-text {
  font-size: 1.1rem;
  line-height: 1.2rem;
  color: white;
  margin-top: -27.5%;
  margin-left: 40%;
  width: 13rem;
}

.homepage-tile.layout .layout-wrapper .tile-text:hover {
  color: white;
  text-decoration: none !important;
}


.homepage-tile:hover {
  transform: scale(1.05);
  text-decoration: none !important;

}

/* .homepage .homepage-tile.image{
    width: 100%;

} */


.subpath-tile {
  float: left;
  margin: 1rem;
  height: 25rem;
  width: 25rem;

}

.subpath-tile.tutorial {
  margin-top: 2rem !important;
}

.subpath-tile .image {
  width: calc(100% * 2);

}


a.subpath-tile:hover {
  text-decoration: none;
  transform: scale(1.05);
}

.subpath-tile .tile-wrapper {
  background: none;
  height: -moz-fit-content;
  height: fit-content;
  width: 100%;
  display: block;
}

.subpath-tile .tile-wrapper .row2 {
  position: relative;
  /* background:rgb(12,186,190); */
  grid-row: 1;
  height: 13rem;
  margin-left: 17.25%;
  margin-top: -7px;
}


.tile-wrapper .row2 .tile-sub-header,
.tile-wrapper .row2 .tile-header,
.tile-wrapper .row2 .tile-description,
.tile-wrapper .row2 .tile-status {
  display: block;
  width: 100%;
  color: white;
  text-decoration: none;

}

.tile-wrapper .row2 .tile-sub-header:hover,
.tile-wrapper .row2 .tile-header:hover {
  text-decoration: none !important;
}

.tile-wrapper .row2 .tile-sub-header {
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 1rem 0rem 1rem;
}

.tile-wrapper .row2 .tile-header {
  font-size: 1.75rem;
  font-weight: bold;
  padding: 0rem 1rem;
  line-height: 2rem;

}

.tile-wrapper .row2 .tile-header.fix-height-3 {
  height: 6rem !important;
}

.tile-wrapper .row2 .tile-description {
  font-size: 1rem;
  line-height: 1.2rem;
  font-weight: normal;
  padding: 0.5rem 1rem;
}

.tile-wrapper .row2 .tile-status {
  position: absolute;
  bottom: 10px;
  font-size: 1rem;
  font-weight: 600;
  padding: 1rem 1rem 0rem 1rem;
}


.button-zeit-bold {
  background-color: #0083d3 !important;
  font-weight: bold;
  color: white;
  font-size: 1.5rem;
}

@media only screen and (max-width: 1440px) {
  .subpath-tile.tutorial {
    float: left;
    margin: 2%;
    height: 20rem;
    width: 24rem;

  }

  .subpath-tile .tile-wrapper .row2 {
    /* background:rgb(12,186,190); */
    grid-row: 1;
    height: 13rem;
    margin-left: 17.25%;
    margin-top: -7px;
  }
}

.homepage-bottom-option {
  width: 100%;
  margin: 0;
  margin-left: calc((100% - 75rem)/2);
  font-size: 1.5rem;
  font-weight: 600;
  display: flex;

}

.homepage-bottom-option .right-option {
  margin-left: auto;
  display: flex;
  flex-direction: column;
}

.homepage-bottom-option .right-option .button-container {
  margin-left: auto;
  padding: .5rem;

}

.homepage-bottom-option .right-option .code-week-button {
  /* padding-left: 1rem;
  padding-right: 1rem; */
  padding: 1rem;
  display: flex;
  align-items: center;
  text-align: center;
  height: 2.75rem;
  border-radius: 0.5rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #0083d3;
  /* background-color: #0cbabe; */
  color: #fff;
  font-size: 1.75rem;
  font-weight: bold;
}

.homepage-bottom-option .right-option .code-week-button:hover {
  text-decoration: none;
  transform: scale(1.025);
}

.homepage-bottom-option .right-option .code-week-button span {
  font-size: 2rem;
  margin-right: 0.5rem;
}

@media only screen and (max-width: 1325px) {
  .homepage-bottom-option {
    margin-left: calc((100% - 50rem)/2);

  }

  .homepage.header2 {
    max-width: 50rem;
    width: 90%;
  }


}

@media only screen and (max-width: 895px) {
  .homepage-bottom-option {
    margin-left: 10%;

  }

  .homepage.header2 {
    max-width: 50rem;
    width: 80%;
  }


}

.homepage-bottom-option span {
  cursor: pointer;
}


/* AI school page*/
.aischool-tiles {
  /* width:calc(75% + 12rem) ; */
  width: 100%;
  display: flex;
  justify-content: center;
}

.aischool-tile {
  float: left;
  width: 22rem;
  height: 15rem;
  margin: 1rem 2rem 1rem 2rem;
  background: #DDD;
}

.aischool-tile.link:hover {
  transform: scale(1.05);
}

.aischool-tile:hover {
  text-decoration: none !important;
}

.aischool-tile-inside {

  min-height: 12rem;
  width: 100%;
  background: #DDD;
}


.aischool-tile .tile-sub-header,
.aischool-tile .tile-header,
.aischool-tile .tile-footer {
  display: block;
  width: 100%;
  color: #333;
  text-decoration: none;
  text-align: center;

}

.aischool-tile .tile-sub-header:hover,
.aischool-tile .tile-header:hover,
.aischool-tile .tile-footer:hover {
  text-decoration: none !important;
}

.aischool-tile .tile-sub-header {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;
}

.aischool-tile .tile-header {
  font-size: 1.5rem;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  padding: 1rem;
  height: 7.5rem;

}

.aischool-tile .tile-header.last-tile {
  height: 8.5rem;
  display: flex;
  align-content: center;
  flex-direction: column;
  color: #0cbabe;
}

.aischool-tile .tile-header.last-tile div:nth-child(2) {
  font-size: 1.2rem;
  font-weight: 600;
}

.aischool-tile .tile-footer {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 1rem;

}

/*AI school page end */


.test-container {
  width: 100%;
  display: flex;
  background-color: rosybrown;
  justify-content: center;
  height: 40rem;
}


.image-container {
  width: 30%;
  height: 50em;
  background: chartreuse;
  grid-template-rows: auto 1rem auto;

}

.image-wrapper {
  grid-row: 0;
  display: inline;
  position: relative
}

.image-container .imagefile {
  /* transform: translateX(0rem); */
  display: block;
  width: 50%;
  float: right;

}

.secondrow {
  grid-row: 3;
  display: block;
  float: none;
  width: 100%;
  height: 10rem;
  background: royalblue;
}


/*  tutorials */


/* - progress bar */
.progress-table-bar {
  position: relative;
  float: left;
  width: calc(100%);
  margin-top: 10px;
  height: 2rem;
}


.progress {
  position: relative;
  float: left;
  width: 100%;
  height: calc(2rem - 2px) !important;
  background-color: #fff !important;
  border-radius: 0.3rem;
  border: 1px solid #AAAAAA;
}


.progress-bar {
  border-radius: calc(0.3rem - 1px);
  background-color: #EEE !important;
  height: 2rem;
}

.progress-div {
  position: relative;
}


.progress-bar span {
  color: #AAA;
  text-align: left;
  vertical-align: middle;
  padding-left: 10px;
  font-size: 1rem;
  font-weight: 600;

}

.progress-div .progress-span {
  color: #AAA;
  text-align: left;
  vertical-align: middle;
  /* padding-left: 10px; */
  font-size: 1rem;
  font-weight: 600;
  position: absolute;
  top: 0.215rem;
  left: 0.5rem;

}

/* - progress bar end */


.grid-box {
  float: left;
  width: 98%;
  display: -webkit-flex;
  -webkit-align-items: flex-start;
  -moz-align-items: center;
  -moz-flex-direction: row;
  align-items: center;
  flex-direction: row;
  flex-wrap: wrap;
  flex-flow: row wrap;
  align-content: flex-start;
  justify-content: center;
  margin: 0 1% 0 1%;
}

.grid-box.multiple-choice {
  margin-top: 50px;
}

.job-box {
  height: auto;
  min-height: 35rem;
  max-height: 40rem;
  width: 320px;
  background-color: #EEE;
  transform: scale(.95);
  margin-bottom: 2rem;
  transition: .3s linear all;
  border-radius: 5px;
  /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
  cursor: pointer;
}

.dog-job-box {
  height: 45rem;
  max-height: 50rem;
}

.aisociety-job-box {
  height: 42rem;
  max-height: 50rem;
  margin-bottom: 3rem;
}


.job-box .content-box span.title {
  font-size: 1.75rem;
  line-height: 1.75rem;
  font-weight: bold;
  height: 5rem;
}

.dog-job-box.job-box .content-box span.title {
  height: 4rem;
}

@media only screen and (min-width: 2000px) {

  .dog-job-box {
    width: 375px;
  }

  .narrow-tile-container {
    width: 100%;
  }

  .job-box .content-box span.title {
    font-size: 1.75rem !important;
  }


}

@media only screen and (min-width: 1661px) and (max-width: 2000px) {

  .dog-job-box {
    height: 46rem;
    width: 20%;
  }

  .job-box .content-box span.title {
    font-size: 1.75rem !important;
  }

  .narrow-tile-container {
    width: 1200px;
  }

  .aisociety-job-box {
    width: 20%;
  }
}

@media only screen and (max-width: 1660px) {
  .dog-job-box {
    width: 20%;
  }

  .job-box .content-box span.title {
    font-size: 1.75rem !important;
  }

  .narrow-tile-container {
    width: 1200px;
  }

  .aisociety-job-box {
    width: 20%;
  }

}

@media only screen and (max-width: 1610px) {

  .dog-job-box {
    height: 46rem;
    width: 20%;
  }

  .job-box .content-box span.title {
    font-size: 1.65rem !important;
  }

  .dog-job-box .content-box span.title {
    font-size: 1.65rem !important;
  }

  .narrow-tile-container {
    width: 1200px;
  }

  .aisociety-job-box {
    width: 20%;
  }

}

@media only screen and (max-width: 1580px) {
  .dog-job-box.job-box {
    width: 20%;
  }

  .dog-job-box {
    height: 46rem;

  }

  .aisociety-job-box {
    width: 20%;
  }

}


@media only screen and (max-width: 1520px) {

  .dog-job-box {
    height: 47rem;

  }

  .job-box .content-box span.title {
    font-size: 1.5rem !important;
  }

  .aisociety-job-box .content-box span.title {
    font-size: 1.6rem !important;
    height: 3.5rem;
  }

  .narrow-tile-container {
    width: 1200px;
  }

  .aisociety-job-box {
    width: 20%;
  }

}

@media only screen and (max-width: 1400px) {

  .aisociety-job-box {
    width: 20%;
  }

  .dog-job-box .content-box span.title {
    font-size: 1.4rem !important;
    height: 3.5rem;
  }

  .dog-job-box.job-box {
    width: 265px;
    height: 48rem;
  }

}

@media only screen and (max-width: 1355px) {
  .dog-job-box .content-box span.title {
    font-size: 1.5rem !important;
    height: 3.5rem;
  }

  .dog-job-box.job-box {
    width: 28%;
    height: 48rem;
  }

  .aisociety-job-box {
    width: 28%;
  }

}

@media only screen and (max-width: 1200px) {

  .dog-job-box .content-box span.title {
    font-size: 1.6rem !important;
    height: 3.5rem;
  }

  .dog-job-box.job-box {
    width: 300px;
    height: 48rem;
  }

  .aisociety-job-box {
    width: 300px;
  }


}


@media only screen and (max-width: 800px) {

  .job-box {
    width: 500px;
  }


  .dog-job-box.job-box {
    width: 500px;
    height: 42rem;
  }


  .job-box .content-box span.title {
    font-size: 1.5rem !important;
  }

  .narrow-tile-container {
    width: 90%;
  }



}

.job-box:hover {
  transform: scale(1);
}

.job-box.margin-top-10 {
  margin-top: 2em !important;
}

.grid-box .box-inside,
.grid-box .grid-1 {
  position: relative;
  float: left;
  width: 100%;
}

.grid-box .grid-2 {
  position: relative;
  float: left;
  width: 50%;
}

.grid-box .grid-3 {
  position: relative;
  float: left;
  width: 33.33333%;
}

.grid-box .grid-4 {
  position: relative;
  float: left;
  width: 25%;
}

.grid-box .grid-5 {
  position: relative;
  float: left;
  width: 20%;
}

.grid-box .grid-6 {
  position: relative;
  float: left;
  width: 16.66667%;
}

/* responsive */
@media only screen and (max-width: 1100px) {
  .grid-box .grid-5 {
    width: calc(calc(100% / 4) - 25px);
  }
}

@media only screen and (max-width: 990px) {
  .grid-box .grid-4 {
    width: calc(calc(100% / 3) - 15px);
  }

  .grid-box .grid-5 {
    width: calc(calc(100% / 3) - 25px);
  }
}

@media only screen and (max-width: 890px) {
  .grid-box .grid-3 {
    width: calc(calc(100% / 2) - 15px);
  }

  .grid-box .grid-2 {
    width: 100%;
  }
}

@media only screen and (max-width: 720px) {
  .grid-box .grid-5 {
    width: calc(calc(100% / 2) - 25px);
  }

  .grid-box .grid-4 {
    width: calc(calc(100% / 2) - 15px);
  }
}

@media only screen and (max-width: 570px) {
  .grid-box .grid-3 {
    width: 100%;
  }

  .grid-box .grid-5 {
    width: 100%;
  }

  .grid-box .grid-4 {
    width: 100%;
    margin-right: 0px;
  }
}

.job-box .image-box {
  position: relative;
  float: left;
  width: 50%;
  margin: -4rem 25% 0 25%;
  height: 7.5rem;
}

.job-box .image-box.ai-scoiety {
  width: 60%;
  margin: -3rem 20% 0 20%;
}


.job-box .image-box img {
  width: 100%;
  height: 100%;
  object-fit: contain;
  object-position: center;
  /* backdrop-filter: blur(10px); */
}

.job-box .content-box {
  position: relative;
  float: left;
  width: 100%;
  height: 300px;
  padding: 20px;
  margin-top: 1rem;
}

.job-box .content-box span.chapter,
.job-box .content-box span.title {
  position: relative;
  float: left;
  width: 100%;
  font-size: 1rem;
  cursor: pointer;
  padding: 0px 0px 0px 0px;
}

.job-box .content-box span.chapter.chapter-padding {
  padding: 1rem 0 0.5rem 0 !important;
}






.job-box .content-box .section {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0px;
  font-size: 14px;
  font-weight: 300;
  padding: 10px 0px;
}

.job-box .content-box .question-section {
  position: relative;
  display: block;
  /* height: 150px; */

  width: 100%;
  margin-top: 0.5rem;
  padding: 20px 0;
  /* padding-top: 1rem; */

  /* overflow-x: hidden;
  overflow-y: auto; */
}

.job-box .content-box .question-section .question span {
  font-weight: 600;
  white-space: pre-line;

}

.job-box .content-box .question-section .question span:hover {
  /* color: transparent;
  background: -webkit-gradient(linear, left top, left bottom, from(#0083d3), color-stop(51%, #0cbabe), to(#15e4ae));
  background: -webkit-linear-gradient(top, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
  -webkit-background-clip: text; */
  font-weight: bold;
}

.job-box .content-box .question-section .question .line {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: #e3e5e6;
  opacity: 0.5;
  height: 1px;
  transition: .3s linear all;
}

.job-box .content-box .question-section .question:hover .line {
  opacity: 1;
}

.job-box .content-box .question-section .question {
  position: relative;
  float: left;
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid #BBB;
}

.content.question {
  /* min-height: calc(calc(100vh - 82px) - 30vh); */
  min-height: calc(100vh - (25vh + 85px));
  margin-top: 0;
  height: auto;
}

/* - video task */



.task-item-lightheader {
  font-size: 1.5rem;
  font-weight: lighter;
  opacity: 0.4;
}

.task-item-mainheader {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0.25rem 0 0.5rem 0;
}

/* .task-item-mainheader>a {} */


/* Multiple Question Video */
.multiple-choise-video {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 560px;
}

.intro-section {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 30rem;
}

.progressBar {
  position: relative;
  float: left;
  width: 100%;
  height: 30px;
  margin-bottom: 30px;
}

.multiple-choise-video .video-wrapper,
.submit-idea-video .video-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: 500px;
  margin: 1.5rem 0;
}

.multiple-choise-video .video-wrapper iframe,
.submit-idea-video .video-wrapper iframe {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
}

.multiple-choise-video .button-video,
.submit-idea-video .button-video {
  position: relative;
  float: left;
  width: 100%;
  height: 50px;
}

.multiple-choise-video .button-video button,
.submit-idea-video .button-video button,
.intro-section .button-video button {
  position: relative;
  width: 12.5rem;
  height: 2.5rem;
  background-color: #EEE;
  color: #AAA;
  border: none;
  border-radius: 0.25rem;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
}


.submit-idea-video .button-video button {
  transform: translateX(-50%);
  float: left;
  left: 50%;
}

.multiple-choise-video .button-video button:first-child {
  float: left;
  left: 0;
}

.multiple-choise-video .button-video button:last-child,
.intro-section .button-video button:last-child {
  float: right;
  right: 0;
}

/* - video task end */

/* - quiz task */
.tittle {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  padding: 10px 30px;
  margin-bottom: 20px;
}

.question-wrapper .tittle {
  font-size: 1.2rem;
}

.question-wrapper .tittle.fail {
  /* background-color: #e74c3c;
  color: #fff; */
  border-bottom: 1px solid red;
}

.question-wrapper .tittle.success {
  /* background-color: #15e4ae;
  color: #fff; */
  border-bottom: 1px solid #15e4ae;
}

.tittle.no-padding-sides {
  padding-left: 0px;
  padding-right: 0px;
}


.question-wrapper .tittle .title-iframe {
  position: relative;
  float: left;
  width: 50%;
}

.question-wrapper .tittle .title-iframe iframe {
  width: 300px;
  height: 300px;
}

.tittle>span {
  position: relative;
  float: left;
  width: 100%;
  white-space: pre-line;
  font-size: 1.2rem;
}

.question-wrapper .tittle span.withFrame {
  width: 50%;
}

.question-wrapper .answers {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
}

.question-wrapper .answers .inside-answers {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  background-color: #DDD;
  /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
  margin-bottom: 10px;
  transition: outline-color .2s linear;
  cursor: pointer;
}

.question-wrapper .answers .inside-answers.disabled {
  cursor: auto;
}

.question-wrapper .answers .inside-answers.iframe-answer {
  height: auto;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-letter {
  height: 300px;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-content {
  height: 300px;
  padding: 0;
}

.question-wrapper .answers .inside-answers.iframe-answer .answer-content iframe {
  position: absolute;
  float: left;
  width: 300px;
  height: 280px;
  left: 50%;
  transform: translateX(-50%);
  margin-top: 10px;
}

/* .question-wrapper .answers .inside-answers.iframe-answer:hover, */
.question-wrapper .answers .inside-answers.iframe-answer.active {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
  margin-bottom: 10px;
  background-color: #0083d3;
}

.question-wrapper .answers .inside-answers input {
  opacity: 0;
  width: 0;
  height: 0;
  display: none;
}

.question-wrapper .answers .inside-answers .answer-letter {
  /* position: relative; */
  position: absolute;
  float: left;
  width: 3rem;
  height: 100%;
  /* background-color: #0083d3; */
  transition: .5s linear all;
}

.question-wrapper .answers .inside-answers .answer-letter span {
  position: absolute;
  float: left;
  color: #fff;
  font-size: 1.5rem;
  font-weight: bold !important;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%)
}


.question-wrapper .answers .inside-answers .answer-content {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding-right: 10px;
}

.question-wrapper .answers .inside-answers .answer-content>span,
.question-wrapper .answers .inside-answers .answer-content .intro {
  position: relative;
  float: left;
  width: calc(100% - 4rem);
  color: #111;
  margin-left: 4rem;
  padding: 20px 0px;
}

.question-wrapper .answers .inside-answers .answer-content .intro.answer-text p {
  margin-bottom: 0;
}

/* .question-wrapper .answers .inside-answers:hover, */
.question-wrapper .answers .inside-answers.active {
  /* box-shadow: 0px 0px 36px -15px rgba(0, 0, 0, 1); */
  /* background-color: #0083d3; */
  outline: #888 solid 2px;
}

.question-wrapper .answers .inside-answers:hover span,
.question-wrapper .answers .inside-answers.active span {
  /* color: #fff; */
  font-weight: bold;
}

.question-wrapper .answers .inside-answers.disabled:hover span {
  font-weight: normal;
}

.question-info {
  margin-top: 1rem;
  position: relative;
  float: left;
  width: 100%;
  height: 25px;
}


.question-info span {
  position: relative;
  float: left;
  font-size: 1.25rem;
}

.question-info.error span {
  color: #e74c3c;
}

.question-info .tooltip-span {
  float: left !important;
}

#multiple-question .tooltip-inner {
  background-color: #0cbabe;
}

.wrong-answer {
  background-color: #999999 !important;
}


.correct-answer-tutorial {
  background-color: #0cbabe !important;
}

.correct-answer-quiz {
  background-color: #00E3A7 !important;
}

.message-correct {
  color: green !important;
}

.message-wrong {
  color: red !important;
}

.points-panel-wrapper {
  position: absolute;
  right: -12rem;
  top: -3.75rem;
  display: flex;
  width: 10rem;
  flex-wrap: wrap;

}

.points-panel-wrapper span {
  margin-left: 3.5rem;
  color: #e7e6e6;
  font-weight: 600;
  font-size: 1.75rem;
}

.points-panel-wrapper .points-panel {
  background-color: #e7e6e6;
  border-radius: 8px;
  text-align: right;
  padding: 5px 10px;
  width: 10rem;
  color: white;
  font-size: 2rem;
  font-weight: bold;
}

/* -- action buttons */


.button-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  left: 50%;
  margin: 2rem 0 1rem 0;
  transform: translateX(-50%);
  max-width: 900px;
}

.add-student-button-wrapper {
  position: relative;
  float: left;
  width: 100%;
  height: auto;
  min-height: 4rem;
  margin: 0.5rem 0 1rem 0;
}

.add-student-button-wrapper button {
  font-size: 1.2rem;
  background-color: #0085d6cc;
  color: #fff;
  /* font-weight: bold; */
}

.close-add-student button:disabled,
.close-add-student button[disabled] {
  opacity: 0.65;
  cursor: progress;
}

.button-wrapper button {
  position: relative;
  width: 12.5rem;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #EEE;
  color: #999;
  font-size: 1.2rem;
  font-weight: bold;
}

.ai-challenge-button {
  display: flex;
  align-items: center;
  text-align: center;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #0cbabe;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;
}

.ai-challenge-button:hover {
  text-decoration: none;
  transform: scale(1.025);
}

.button-wrapper button:hover {
  color: #333 !important;
}

.login-btn {
  font-weight: bold;
  width: auto;
  font-size: 1.25rem;
  margin: 1.6rem 0.5rem;
  color: #333;
  transform: translateX(-200%);
  display: block;

}

.login-btn:hover {
  text-decoration: none;
  color: #333 !important;
}


.button-wrapper button.firstBtn {
  float: left;
}

.button-wrapper button.secondBtn {
  position: absolute;
  float: left;
  left: 50%;
  transform: translateX(-50%);
}

.button-wrapper button.thirdBtn {
  float: right;
}

/* -- action buttons end */

/* - quiz task end */



/*Tutorials end*/


/* definition */

.definition-panel-wrapper {
  width: 95%;
  height: -moz-fit-content;
  height: fit-content;
  margin: 1rem 2.5% 3rem 2.5%;


}

.definition-image-wrapper {
  display: block;
  min-width: 868px;
  width: 868px;
  height: auto;
  margin: 0 auto;
  position: relative;

}

.definition-panel-wrapper .definition-image-wrapper img {
  width: 768px;
  height: auto;
}

.definition-panel-wrapper .definition-image-wrapper .item1 {
  position: absolute;
  top: 205px;
  left: 20px;
  /* top:33.4%;
    left:-10%; */
}

.definition-panel-wrapper .definition-image-wrapper .item2 {
  position: absolute;
  top: 140px;
  left: 550px;
  /* top:22.75%;
    left:73.5%; */
}

.definition-panel-wrapper .definition-image-wrapper .item3 {
  position: absolute;
  top: 272px;
  left: 750px;
  /* top:47.5%;
    left:100%; */
}

.definition-panel-wrapper .definition-image-wrapper .item4 {
  position: absolute;
  top: 450px;
  left: 460px;
  /* top:76.4%;
    left:61%; */
}

.definition-panel-wrapper .definition-image-wrapper .item5 {
  position: absolute;
  top: 520px;
  left: 208px;
  /* top:90%;
    left:25%; */
}

.box-border {
  border: 3px solid rgb(0, 131, 211);
  background-color: #FFF;
  border-radius: 1rem;
  padding: 1rem 0.5rem;
  width: 200px;
  color: rgb(0, 131, 211);
  ;
  text-align: center;
  cursor: pointer;
  font-weight: 600;

}

.box-border:hover {
  transform: scale(1.1);
  transition: .1s linear all;
  ;
}

.definition.definition-footer-link {
  display: block;
  font-size: 1.5rem;
  font-weight: normal !important;
  margin: 1rem auto;
  margin-left: 5%;
  color: #000;
  font-weight: lighter;
}

/*definition end */

.overlay-panel-container {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0px;
  left: 0px;
  height: 100%;
  width: 100%;
  background-color: #00000055;
  z-index: 99999;

}


.overlay-panel,
.overlay-panel-home {
  position: relative;
  background-color: white;
  width: 90%;
  height: auto;
  z-index: 100000;
  padding: 2rem;
  overflow-y: hidden;
}

.overlay-panel {
  padding: 2rem 0;
  min-height: 25rem;
  max-height: 90%;
  max-width: 1200px;
  display: grid;
  grid-template-rows: 5rem auto 0rem;
  box-shadow: 2px 2px 20px rgba(0, 0, 0, 0.5);
}

.overlay-panel-home {
  padding: 1rem 1rem 0.5rem 1rem;
  min-height: 200px;
  max-height: 800px;
}

.overlay-panel.view-mode-message {
  display: flex;
  min-height: 2rem;
  max-width: 35rem;
  justify-content: center;
}

.view-mode-message .panel-content .message-title {
  font-size: 1.25rem;
}

.view-mode-message .button-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  left: auto;
  transform: none;
  margin: 1rem 0 0 0;
}

.view-mode-message .button-wrapper button {
  margin: 0 0.5rem
}

.shadow {
  box-shadow: 13px 13px 15px 16px #000;
}


.overlay-panel .close,
.overlay-panel-home .close {
  position: absolute;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  text-align: center;
  vertical-align: middle;
  font-size: 2rem;
  line-height: 3rem;
}

.overlay-panel .close::after,
.overlay-panel-home .close::after {
  content: "X";
}


.overlay-panel .close:hover {
  background-color: #00000022;
}

.overlay-panel .definition-header {
  font-size: 2rem;
  font-weight: bold;
  margin-left: 2rem;
  display: flex;
  align-items: center;
}

.overlay-panel .section-wrapper {
  padding: 0 2rem;
  max-height: 60vh;

  overflow: auto;
}

.overlay-panel .section {
  padding: 1.5rem 0 1rem 0;
  font-size: 1.5rem;

}

.overlay-panel-home .section {
  padding: 0 1.5rem 1rem 1.5rem;
  font-size: 1.5rem;
  margin-top: 1.5rem;
  height: calc(100% - 1.5rem);
  overflow-y: auto;

}

.overlay-panel .section span.section-title {
  font-weight: bold;
}

.overlay-panel-home .section>p,
.overlay-panel .section>p {
  margin: 0;
}

.overlay-panel .close,
.overlay-panel-home .close {
  position: absolute;
  right: 0;
  top: 0;
}

.add-student-info.col-md-12 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.gallery {
  grid-template-columns: 0 auto 0;
  display: grid;
  width: 90%;
  max-width: 1024px;
  max-height: 90vh;
  height: 100%;
  margin: auto;
}

.gallery .navigator {
  height: 100%;
  width: 100%;
  display: flex;
  justify-items: center;
  justify-content: center;
  align-items: center;
}

.gallery .arrow {
  margin: 1rem;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
}

.navigator.back {
  grid-column: 1;
}

.navigator.next {
  grid-column: 3;
}


.arrow.back {
  transform: rotateY(180deg);
}

.overlay-panel-home {
  position: relative;
  background: #FFF;
  max-width: 1024px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  grid-column: 2;
  overflow: hidden;
}


.overlay-panel-home .section p {
  margin-top: 1rem;
  font-size: 1.5rem;
  text-align: justify;
}

.overlay-panel-home .section p span.points {
  font-weight: bold;
  margin-left: 2rem;
}

.overlay-panel .actions {
  font-weight: 600;
  position: relative;
  display: block;
  width: 100%;
  grid-template-columns: 10rem auto 10rem;
}

.overlay-panel.prompt {
  min-height: auto;
  height: auto;
  width: auto;
  max-width: 90%;
}

.overlay-panel.include-audio {
  grid-template-rows: 5rem 1fr auto;
  ;
}

.previous-card {
  position: relative;
  grid-column: 1;
  float: left;
  cursor: pointer;
}

.next-card {
  position: relative;
  grid-column: 3;
  float: right;
  cursor: pointer;
}

.intro-wrapper {
  position: relative;
  float: left;
  width: 100%;
  min-height: 30rem;
}

.intro-wrapper .intro {
  font-family: "Open Sans";
  margin-top: 1.5rem;
  position: relative;
  width: 100%;
  /* min-height: 25rem; */
}

.intro-section .intro-wrapper .intro h1,
.intro h1 {
  font-size: 1.75rem;
  font-weight: 600;
}

.intro-section .intro-wrapper .intro h2,
.intro h2 {
  font-size: 1.5rem;
  font-weight: 600;
}

.intro-section .intro-wrapper .intro h3,
.intro h3 {
  font-size: 1.25rem;
  font-weight: 600;
}

.intro-section .intro-wrapper .intro h4,
.intro h4 {
  font-size: 1.175rem;
  font-weight: 600;
}

.intro-section .intro-wrapper strong,
.intro strong {
  font-size: 1.15rem;
  font-weight: 600;
}

.intro-section .intro-wrapper em,
.intro em {
  font-style: italic;
}

.intro-section .intro-wrapper .intro p,
.intro p {
  font-size: 1.25rem;
  margin-bottom: 2rem;
}

.intro-section .intro-wrapper .intro>ol {
  margin-bottom: 1rem;
}

.intro-section .intro-wrapper .intro>ul {
  margin-bottom: 1rem;
}

.intro.question-title p {
  font-size: 1.2rem;
}

.intro-section .intro-wrapper .intro ul li,
.intro ul li {
  list-style: square inside none;
  font-size: 1.25rem;
}

.intro-section .intro-wrapper .intro ul li>ul,
.intro ul li>ul {
  margin-left: 1.25rem;
}

.intro-section .intro-wrapper .intro ul li>ul li,
.intro ul li>ul li {
  list-style: circle inside none;
}

.intro-section .intro-wrapper .intro ol li,
.intro ol li {
  display: list-item;
  list-style: decimal inside none;
  font-size: 1.25rem;
}

.intro-section .intro-wrapper .intro ol li>ol,
.intro ol li>ol {
  margin-left: 1.25rem;
}

.intro-section .intro-wrapper .intro ol li>ol li,
.intro ol li>ol li {
  list-style: lower-alpha inside none;
}

.intro-section .intro-wrapper .intro img,
.intro img {
  width: auto;
  height: auto;
  margin: 1.5rem 0 1.5rem 0;
}

.intro-section .intro-wrapper .code-playground {
  margin-top: 2rem;
}

/* Not required .intro-section .intro-wrapper .intro pre{
    background-color: #EEEEEE77;
    padding: 1rem;
}

.intro-section .intro-wrapper .intro code{
    font-size: 1rem;

} */

.intro .intro-code-block {
  margin-bottom: 1rem;
}

.intro code,
.intro.question-title code,
.intro.answer-text code {
  background: #EEE;
  padding: 2px 4px;
  /* margin:0px 4px; */
  border-radius: 5px;
  font-size: 1rem;
  font-family: monospace !important;
  /* font-weight: 600; */
  color: black;
  white-space: pre-line;
}

.intro code {
  white-space: normal;
}



.intro-wrapper>iframe {
  position: relative;
  width: 100%;
  min-height: 30rem;
  /* height: fit-content; */
}


/* styling registration color, background */
.reg-style-wrapper .form-group input {
  background-color: #028ccf;
  color: #fff;
}

.reg-style-wrapper .form-group input::placeholder {
  color: white;
  opacity: 1;
  /* Firefox */
}

.reg-style-wrapper .form-group input[disabled] {
  opacity: 0.4;
}

/* status section */

.container-center {
  margin: 5% 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.status-scoring-info {
  text-align: justify;
  display: flex;
  font-size: 1.25rem;
  justify-content: center;
  flex-wrap: wrap;
  margin: 0% 5%;
}

.status-tile {
  background-color: #e7e6e6;
  height: 25rem;
  width: 18rem;
  margin: 1rem 1.5rem;
  padding: 3rem 1.5rem;
  float: left;
}

@media only screen and (max-width: 1024px) {

  .container-limit.wide-limit {
    max-width: 768px;
  }

}

.status-tile .status-header {
  font-size: 2rem;
  line-height: 1;
  font-weight: bold;
  margin: 1rem 0;
  height: 2.5rem;
}

.status-tile .section-header {
  font-size: 1.25rem;
  font-weight: bold;
  margin-top: 2.5rem;
}

.status-tile .section-content {
  font-size: 1.25rem;

}

.status-text-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.status-text {
  font-size: 2rem;
  font-weight: bold;
}

.status-text-wrapper .leaderboard-link {
  margin-left: 1rem;
  font-size: 2rem;
  font-weight: bold;
}

@media only screen and (max-width: 1024px) {
  .status-text-wrapper {
    margin: 1rem 5rem;
    display: flex;
    justify-content: center;
  }

  .status-text {
    font-size: 2rem;
    font-weight: bold;
    text-align: center;
  }

  .status-text-wrapper .leaderboard-link {
    margin-left: 1rem;
    font-size: 2rem;
    font-weight: bold;
  }
}

/* status section end */

/* taskpool */

.tasks {
  margin-top: 3rem;
}

/* taskpool end */

/*  Loading Panel */

.loading-container {
  height: calc(100vh - (25vh + 85px));
  background-color: #EEEEEE05;
  display: grid;
  justify-content: center;
  align-items: center;
}

.loading-container .loading-panel {
  font-size: 3rem;
  font-weight: bold;
}

/* Loading Panel end */

p.intro-desc {
  margin-left: 1rem;
  text-align: justify;
  text-justify: auto;
}

p.intro-desc strong {
  font-weight: bold
}

.message-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}


/* Course Content section */

/* - coding console */

.intro-wrapper .code-panel-wrapper {
  display: grid;
  width: 100%;
  margin: 1rem 0;
  position: relative;
  /* grid-template-columns: 50% 50%; */
}

.intro-wrapper .code-panel-wrapper .code-panel {
  /* grid-column: 1; */
  /* display: grid; */
  max-width: 100% !important;

}

.intro-wrapper .code-panel-wrapper .code-panel .react-codemirror2 {
  border: 1px solid #DDD;
  max-width: 100% !important;
}

.intro-wrapper .code-panel-wrapper .code-panel button {
  /* height: 1.5rem; */
  float: right;
  background: #EEE;
  margin: 2px;
  padding: 5px 10px;
  border: none
}

.intro-wrapper .code-panel-wrapper .terminal-panel {
  border: 1px solid #DDD;
}

.loader-panel {
  height: 100%;
  width: 100%;
  display: grid;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 10;
  background: #000000BB;
}

/* cm-s-idea updated the styling component */
.intro-wrapper .code-panel-wrapper .terminal-panel .terminal-block .cm-s-idea {
  font-family: monospace;
  font-size: 16px;
  background: #272822;
  color: #f8f8f2;
}

.intro-wrapper .code-panel-wrapper .terminal-panel .terminal-block .CodeMirror pre.CodeMirror-line {
  white-space: normal;
  max-width: 100%;
}

.code-panel-wrapper .code-panel .alert {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  padding-top: 0;
  padding-bottom: 0;
}



/* - coding console end */

/* memory */

.memory-menu {
  margin-left: 25%;
}

.memory-menu-background {
  position: absolute;
  max-height: 80%;
  margin-left: -20%;
  margin-top: -2.5em;
  z-index: -1;
}

.memory-game {
  display: inline-flex;
  flex-wrap: wrap;
  grid-gap: 2%;
  gap: 2%;
  width: 90%;
  justify-content: center;
  margin-left: 5%;
}

.memory-game-description {
  flex-basis: 80%;
}

.memory-game-button {
  width: 12.5rem;
  height: 2.5rem;
  background-color: #EEE;
  color: #AAA;
  border: none;
  border-radius: 0.25rem;
  outline: none;
  cursor: pointer;
  font-size: 17px;
  font-weight: bold;
}

.memory-align-bottom {
  flex-basis: 80%;
  /* bottom: 10px; */
  /* width: 28%; */
}

.memory-align-bottom img {
  width: 100px;
  float: right;
}

.mini-button {
  width: 10.5rem;
  height: 2rem;
  font-size: 15px;
  bottom: auto !important;
}

.memory-game-button:disabled {
  opacity: 0.7;
}

.memory-game-button:enabled:hover {
  color: #333 !important;
}

.memory-game-field {
  flex-basis: 100%;
  display: grid;
  grid-template-columns: repeat(4, 25vh);
  grid-gap: 10px 15px;
  gap: 10px 15px;
  justify-content: center;
}

.memory-game-field .cards img {
  max-height: 25vh;
  border: 1px solid black;
  transition: opacity .2s ease-out;
}

.correct-pair {
  color: #28a745;
  font-size: 1.5em;
}

.false-pair {
  color: #dc3545;
  font-size: 1.5em;
}

.hidden {
  display: none;
}

.memory-quiz-area {
  flex-basis: 70%;
}

.memory-quiz-field {
  display: grid;
  grid-template-columns: repeat(2, 40%);
  grid-gap: 10px 15px;
  gap: 10px 15px;
  justify-content: center;
  justify-items: center;
  max-width: 800px;
  margin: 0 auto;
  padding-bottom: 3rem;
}

.memory-quiz-field img {
  max-height: 350px;
}

.memory-quiz-field .answer-content {
  position: relative;
  width: 100%;
  background-color: #DDD;
  margin-bottom: 10px;
  transition: outline-color .2s linear;
  cursor: pointer;
  height: 3rem;
  max-width: 350px;
  padding: 0.5rem 0;
  text-align: center;
  font-size: 1.25rem;
}

.memory-quiz-field .answer-content.active {
  outline: #888 solid 2px;
  font-weight: bold;
}

.memory-quiz-field .answer-content.correct-answer {
  background-color: #007bff;
  color: #fff;
}

.memory-quiz-field .answer-content p {
  position: relative;
  float: left;
  width: 100%;
  height: 100%;
  padding-right: 10px;
}


.evaluate-quiz {
  place-self: center;
}

.memory-timeline {
  margin-left: 7%;
  max-width: 85%;
}

.memory-timeline img {
  max-width: 100%;
}

.memory-timeline .tiles {
  margin: 15px 0 50px 0;
}

.memory-timeline .tiles img {
  display: inline-block;
  max-width: 100px;
  margin-right: 10px;
  cursor: pointer;
}

.timeline-bottom {
  max-width: 80%;
  min-height: 3rem;
  margin-left: 5%;
}

.timeline-bottom button {
  float: right;
}

.overlay-panel .memory-tile {
  display: flex;
  align-items: center;
  justify-content: center;
}

.overlay-panel .memory-tile img {
  max-height: 100%;
}


/* memory end */



/* Course Content section  end*/

/* user profile - append */
.delete-account {
  display: flex;
  position: absolute;
  right: 0;
  bottom: 0;
  font-size: 1rem;
  padding: 0.25rem 0.5rem;
  background: rgba(0, 0, 0, 0.025);
  cursor: pointer;
}

.delete-account:hover {
  text-decoration: underline;
}

/* end user profile  - append */


/* Classroom section */
.button-room-bold {
  background-color: #0083d3 !important;
  font-weight: bold;
  color: white;
}

.button-room {
  background-color: #0083d3 !important;
  font-weight: bold;
  color: white;
}

.container-room {
  width: 75%;
  max-width: 800px;
  margin: 0 auto;
}

/* Markdown tables */

.intro table {
  width: 100%;
  table-layout: fixed;
}

.intro thead {
  font-weight: bold;
  border-bottom: 1px solid;
}

.intro tr:nth-child(even) {
  background-color: #f2f2f2;
}

.intro td,
th {
  padding-left: 1em;
  padding-right: 1em;
}



/* *********** */
/* Collapsible */
/* *********** */
.faq-content {
  margin: 2rem 0;

}

.faq-panel-heading {
  font-weight: bold;
  cursor: pointer;
}

.faq-panel-heading a:before {
  font-family: 'Glyphicons Halflings';
  color: #000;
  /* content: '\e114'; */
  content: '\25BA';
  float: right;
  transition: all 0.5s;
}

.faq-panel-heading.active a:before {
  transform: rotate(90deg);
}


/* Pioneer women - Museum Catalogue */
.catalog-download-strong {
  font-weight: 600;
  margin-top: 0.5rem;
  margin-bottom: 1rem;
}

.catalog-button-container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  max-width: 60%;

}

a.catalog-button-wrapper {
  text-decoration: none;
}

a.catalog-button-wrapper:hover {
  text-decoration: none;
}

.catalog-button-wrapper .catalog-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 2.5rem;
  border-radius: 0.25rem;
  border: none;
  cursor: pointer;
  outline: none;
  background-color: #0cbabe;
  color: #fff;
  font-size: 1.2rem;
  font-weight: bold;

  background-color: #0083d3;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1.25rem;
  width: -moz-fit-content;
  width: fit-content;
  min-width: 13.5rem;
}

.catalog-button-wrapper .catalog-button .icon {
  height: 1em;
}

.portraits {
  margin-top: 3rem;
}

.category-selector {
  width: 99%;
  margin-bottom: 2.5rem;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.category-selector .left-container {
  width: 50%;
  display: flex;
  flex-direction: column;
  margin-top: auto;
  margin-bottom: auto;
}

.category-selector .left-container h3 {
  align-self: flex-start;
  /* margin-top: 3rem; */
  margin-left: 0.5rem;
}

.category-selector .right-container {
  width: 40%;
  /* display: flex;
    flex-direction: column; */
  right: 0;
}

.category-selector .left-container .category-wrapper {
  display: flex;
  flex-direction: row;
  /* margin-bottom: 1rem; */
}

.category-selector .category-wrapper .clear {
  transform: translateY(7.5px);
  font-size: larger;
  font-weight: bold;
  cursor: pointer;
  margin-left: 1rem;
  width: 30px;
  height: 30px;
  border-radius: 25%;
  border: 1px solid darkgray;
  text-align: center;
}

.category-selector .category-wrapper p {
  border: 1px solid #212529;
  border-radius: 5px;
  margin: 5px 10px;
  padding: 5px 15px;
  text-align: center;
  float: left;
  cursor: pointer;
}


.category-selector .category-wrapper p.selected {
  border: 1px solid #028ccf;
  background-color: #0CBABE;
  font-weight: bold;
  color: #fff;
}

.portraits-list {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  margin: 0 auto;
}

.portrait-frame {
  float: left;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  width: calc(calc(100% / 3) - 10px);
  min-height: 500px;
  margin-right: 10px;
  margin-top: 50px;
  color: #fff;
}

.portrait-frame.lightblue-green-gradient-background {
  background: linear-gradient(to bottom, #0083d3 0, #0cbabe 51%, #15e4ae 100%);
}

.portrait-box {
  position: relative;
  width: 90%;
  min-height: 340px;
  display: flex;
  flex-direction: column;
  align-content: center;
  background-color: #fff;
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: contain;
  margin-top: 1rem;
  margin-bottom: 1rem;
  cursor: pointer;
}

.portrait-title {
  margin-top: .5rem;
  align-self: center;
  width: 95%;
}

.portrait-title h1 {
  padding: 5px 1rem;
  text-align: left;
  font-size: 20px;
  font-weight: bold !important;
  background-color: #0083d3;
}

.portrait-subtitle {
  align-self: flex-start;
  margin: 5px;
  padding-left: 1rem;
  font-style: italic;
}

.portrait-subtitle h2 {
  font-size: 15px;
  margin: 5px;
  color: #0083d3;
}

.portrait-summary {
  background-color: #fff;
  width: 100%;
  height: max-content;
  min-height: 125px;
  position: relative;
  align-self: flex-end;
  /* margin-top: 2rem; */
  border-bottom: 1px solid #BBB;
  border-left: 1px solid #BBB;
  border-right: 1px solid #BBB;

  color: #000;
  padding: 5px 10px;
  text-align: justify;
  float: left;
}

.portrait-box .portrait-image {
  width: 100%;
}

.portrait-box .portrait-image img {
  width: 100%;
  margin: 0;
}

.pioneer-woman.overlay-modal {
  position: relative;
  background-color: white;
  width: 70%;
  height: auto;
  z-index: 100000;
  padding: 2rem;
  overflow-y: hidden;
  padding: 1rem 1rem 1rem 1rem;
  min-height: 200px;
  max-height: 100vh;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.pioneer-woman.overlay-modal .close {
  align-self: flex-start;
  height: 3rem;
  width: 3rem;
  cursor: pointer;
  text-align: right;
  vertical-align: top;
  font-size: 2rem;
}

.pioneer-woman.overlay-modal .close::after {
  content: "X";
}

.pioneer-woman.overlay-modal .modal-content-wrapper {
  display: flex;
  flex-direction: row;
}

.pioneer-woman.overlay-modal .modal-content-wrapper .image-left {
  width: 50%;
  align-self: flex-start;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-content: center;
  margin: auto;
}

.pioneer-woman.overlay-modal .modal-content-wrapper .image-left img {
  /* height: 450px; */
  height: 29.5rem;
}

.pioneer-woman.overlay-modal .modal-content-wrapper .description-right {
  width: 50%;
  /* margin-right: 1.5rem; */
  margin-left: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* justify-content: space-between; */
}

.pioneer-woman.overlay-modal .modal-content-wrapper .description-right .description {
  width: 100%;
}

.pioneer-woman.overlay-modal .modal-content-wrapper .description-right .description ul li {
  list-style-type: disc;
  margin-left: 2.5rem;
}

.pioneer-woman.overlay-modal .modal-content-wrapper .description-right img.person {
  min-height: 7.5rem;
  height: 150px;
  margin-bottom: 1rem;
}

/* Pioneer women - Timeline */

.pioneer-women-timeline .content {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.pioneer-women-timeline #museum-frames {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  align-items: end;
  padding-bottom: 50px;
}

.pioneer-women-timeline #woman-portrait {
  height: 350px;
  width: 350px;
}

.pioneer-women-timeline #woman-nameplate {
  width: 250px;
}

.pioneer-women-timeline #text-frame-background {
  width: 836px;
  min-width: 836px;
  height: 350px;
  background-image: url('/images/pioneer-women/empty_prose_frame.svg');
  background-repeat: no-repeat;
  padding: 40px;
}

.pioneer-women-timeline #text-frame-content {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 120%;
  color: white;
  height: 100%;
}


.pioneer-women-timeline .horizontal-scroll {
  overflow-x: scroll;
}

.pioneer-women-timeline #scrollable-timeline {
  position: relative;
  width: 250vw;
  padding: 50px;
}


/* Pioneer women - Menu */

.pioneer-women-menu .content {
  display: flex;
  justify-content: space-around;
  flex-direction: column;
}

.pioneer-women-timeline #museum-frames {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 50px;
  gap: 50px;
  align-items: end;
  padding-bottom: 50px;
}

.pioneer-women-timeline #woman-portrait {
  height: 350px;
  width: 350px;
}

.pioneer-women-timeline #woman-nameplate {
  width: 250px;
}

.pioneer-women-timeline #text-frame-background {
  width: 836px;
  min-width: 836px;
  height: 350px;
  background-image: url('/images/pioneer-women/empty_prose_frame.svg');
  background-repeat: no-repeat;
  padding: 40px;
}

.pioneer-women-timeline #text-frame-content {
  overflow: scroll;
  overflow-x: hidden;
  font-size: 120%;
  color: white;
  height: 100%;
}


.pioneer-women-timeline .horizontal-scroll {
  overflow-x: scroll;
}

.pioneer-women-timeline #scrollable-timeline {
  position: relative;
  width: 250vw;
  padding: 50px;
}

.pioneer-women-timeline .submit-parent-wrapper {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: flex-start;
  margin-top: 1rem;
}

.pioneer-women-timeline.sources-icon {
  display: flex;
  align-self: flex-end;
  margin-right: 2rem;
}

.pioneer-women-timeline.sources-icon img {
  width: 7rem;
  float: left;
  cursor: pointer;
}

.pioneer-women-timeline.sources-icon img:hover {
  transform: scale(1.1);
}

/* CODE WEEK */
.code-week-page {
  /* width: 85%; */
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
}

.code-week-page .font-sub-header {
  font-size: 1.25rem;
  font-weight: 600 !important;
}

.code-week-page .header1 {
  /* width: 90%; */
  height: auto;
  margin: 1% 5%;

}

.code-week-page .header2 {
  /* max-width: 75rem; */
  width: 90%;
  margin: 1rem auto;
}

.code-week-page .packet-tiles {
  max-width: 1440px;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* float: left; */
  margin: auto;
  /* height: 100%; */
  padding: 0px 15px;
  /* padding-bottom: 30px; */
}

.code-week-page .tile-wrapper {
  position: relative;
  width: 23rem;
  margin: 2rem 2.25rem;
}

.code-week-page .tile-wrapper:hover {
  color: white;
  text-decoration: none !important;
}


.code-week-page .tile-wrapper:hover {
  transform: scale(1.05);
  text-decoration: none !important;
}

.code-week-page .tile-image {
  display: flex;
  align-self: center;
}

.code-week.paket-logo {
  height: 7em;
}

.code-week.paket-top-container {
  display: flex;
  grid-gap: 5em;
  gap: 5em;
}
